import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { MainBackground } from 'shared/components/MainBackground/MainBackground.components';
import { Layout } from 'shared/components/Layout/Layout.components';
import { useMainBackgroundContext } from 'shared/contexts/mainBackground.contexts';

import MainBackgroundImage from 'assets/images/background/main-background.png';

export const Dashboard = () => {
  const {
    backgroundColor,
    backgroundImage,
    setBackgroundColor,
    setBackgroundImage,
  } = useMainBackgroundContext();
  useEffect(() => {
    setBackgroundColor('#FFFBF4');
    setBackgroundImage(`url("${MainBackgroundImage}")`);
  }, [setBackgroundColor, setBackgroundImage]);
  return (
    <MainBackground
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
    >
      <Layout width="1070px" paddingTop="104px">
        <Outlet />
      </Layout>
    </MainBackground>
  );
};
