export enum ServerStateKey {
  COIN_HISTORY_BY_DATE = 'COIN_HISTORY_BY_DATE',
  COIN_MARKET_CHART = 'COIN_MARKET_CHART',
  V2_COIN_MARKET_CHART = 'V2_COIN_MARKET_CHART',
  COIN_VS_CURRENCIES_PRICE = 'COIN_VS_CURRENCIES_PRICE',
  ETH_BALANCE_FROM_ACCOUNT = 'ETH_BALANCE_FROM_ACCOUNT',
  BASKET_BALANCE_FROM_CONTRACT = 'BASKET_BALANCE_FROM_CONTRACT',
  RISK_SCORE = 'RISK_SCORE',
  PERFORMANCE_DATA = 'PERFORMANCE_DATA',
  USER_BASKET_ADDRESS = 'USER_BASKET_ADDRESS',
  PAST_EVENTS = 'PAST_EVENTS',
}
