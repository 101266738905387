import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { useMetaMaskContext } from 'shared/contexts/metamask.contexts';
import { DepositContainer } from 'pages/createAccounts/Deposit/DepositContainer';
import {
  FormSchema,
  createInitialValues,
  schema,
} from 'pages/createAccounts/Deposit/depositFormHelper';
import { GenericAPIWrapper } from 'shared/components/apiWrappers/GenericAPIWrapper.component';

import POLYGON_MATIC_LOGO from 'assets/images/logos/polygon-matic-logo.png';
import { useRiskScoreContext } from 'shared/contexts/riskScore.contexts';
import { useGetEthBalanceFromAccount } from 'shared/hooks/contracts/queries/useGetEthBalanceFromAccount';
import { useCreateAccount } from 'shared/hooks/contracts/mutations/useCreateAccount';
import { useSnackbarContext } from 'shared/contexts/snackbar.contexts';
import { CircularProgress } from '@mui/material';

export const Deposit = () => {
  const { provider, accountAddress, contract } = useMetaMaskContext();
  const { handleOpen } = useSnackbarContext();
  const {
    data: ethBalanceFromAccount,
    isLoading: isLoadingEthBalanceFromAccount,
    error: ethBalanceFromAccountError,
  } = useGetEthBalanceFromAccount(provider, accountAddress);
  const { isLoading: isLoadingCreateAccount, mutateAsync: createAccount } =
    useCreateAccount();
  const { riskScore } = useRiskScoreContext();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormSchema>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      ...createInitialValues(),
    },
  });

  const handleFormSubmit = async ({ depositValue }: FormSchema) => {
    try {
      // NOTE: Problem with this is it doesn't interact with the react query refetches
      // if (!socketContract || !accountAddress) return;
      // console.log('accountcreated event listener created');
      //       Need to get and set userBasketAddress in the callback but dont have the methods yet
      // socketContract.once(
      //   'AccountCreated',
      //   {
      //     filter: {
      //       owner: accountAddress,
      //     },
      //     fromBlock: 0,
      //   },
      //   (error, event) => {
      //     console.log('error:', error);
      //     console.log('event:', event);
      //     navigate('/congratulations');
      //   }
      // );

      await createAccount({
        contract,
        accountAddress,
        depositValue,
        riskScore,
        provider,
      });
      navigate('/congratulations');
    } catch (error: any) {
      handleOpen('Error: ' + error.message);
    }
  };

  return (
    <DepositContainer>
      <GenericAPIWrapper
        isLoading={isLoadingEthBalanceFromAccount}
        error={ethBalanceFromAccountError}
      >
        <>
          <Typography variant="h4">
            {ethBalanceFromAccount
              ? 'Fund your account to start trading'
              : 'Sorry!'}
          </Typography>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="deposit-content">
              {ethBalanceFromAccount ? (
                <div className="deposit-content-header-container-success">
                  <div className="deposit-content-header-container-success-wrapper">
                    <CheckCircleIcon className="deposit-content-icon-header" />
                    <Typography
                      className="deposit-content-header-container-success-message"
                      variant="subtitle1"
                    >
                      Your wallet is successfully connected
                    </Typography>
                  </div>
                  <Divider className="deposit-content-divider" />
                  <Typography
                    className="deposit-content-fund-header"
                    variant="subtitle1"
                  >
                    How much would you like to fund?
                  </Typography>
                </div>
              ) : (
                <div className="deposit-content-header-container-cancel">
                  <CancelIcon className="deposit-content-icon-header-cancel" />
                  <Typography variant="body1">
                    Something went wrong! Please click{' '}
                    <span
                      className="deposit-content-redirect-span"
                      onClick={() => navigate('/create/risk-assessment')}
                    >
                      here
                    </span>{' '}
                    to restart the process.
                  </Typography>
                </div>
              )}
              {ethBalanceFromAccount ? (
                <TextField
                  className="deposit-content-input"
                  type="number"
                  helperText={
                    ethBalanceFromAccount
                      ? `${ethBalanceFromAccount} MATIC available`
                      : 'Wallet not connected'
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div className="deposit-content-input-adornment">
                          <img
                            src={POLYGON_MATIC_LOGO}
                            alt="Polygon Matic logo"
                          />
                          <Typography className="deposit-content-input-text">
                            MATIC
                          </Typography>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ ...register('depositValue'), step: 0.01 }}
                />
              ) : (
                <></>
              )}
            </div>
            {ethBalanceFromAccount ? (
              <Button
                className="deposit-next-button"
                variant="outlined"
                type="submit"
                disabled={!isValid || isLoadingCreateAccount}
              >
                {isLoadingCreateAccount
                  ? 'Loading...this may take a few minutes'
                  : 'Next - Fund your account'}
                {isLoadingCreateAccount ? (
                  <CircularProgress size="1rem" />
                ) : (
                  <ArrowForwardIcon className="deposit-next-button-icon" />
                )}
              </Button>
            ) : (
              <></>
            )}

            {errors?.depositValue ? (
              <Typography className="deposit-error-text">
                {errors.depositValue.message}
              </Typography>
            ) : (
              <></>
            )}
          </form>
        </>
      </GenericAPIWrapper>
    </DepositContainer>
  );
};
