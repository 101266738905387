import { useReducer, createContext, ReactNode, useContext } from 'react';
import { SnackbarOrigin } from '@mui/material/Snackbar';

interface SnackbarContextInterface {
  horizontal: SnackbarOrigin['horizontal'];
  vertical: SnackbarOrigin['vertical'];
  open: boolean;
  message: string;
  handleClose: () => void;
  handleOpen: (
    message: string,
    horizontal?: SnackbarOrigin['horizontal'],
    vertical?: SnackbarOrigin['vertical'],
  ) => void;
}

interface SnackbarState extends SnackbarOrigin {
  open: boolean;
  message: string;
}

enum SnackbarActionType {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

interface SnackbarPayload extends Omit<SnackbarState, 'open'> {}

interface SnackbarAction {
  type: SnackbarActionType;
  payload: SnackbarPayload;
}

const initializeSnackbarStateValues = (): SnackbarState => ({
  open: false,
  message: '',
  horizontal: 'center',
  vertical: 'bottom',
});

const initializedSnackbarStateValues = initializeSnackbarStateValues();

const initializeSnackbarContextValues = (): SnackbarContextInterface => ({
  ...initializedSnackbarStateValues,
  handleClose: () => null,
  handleOpen: () => null,
});

const {
  horizontal: initializedHorizontal,
  vertical: initializedVertical,
  message: initializedMessage,
  open,
  handleClose,
  handleOpen,
} = initializeSnackbarContextValues();

const SnackbarContext = createContext<SnackbarContextInterface>({
  horizontal: initializedHorizontal,
  vertical: initializedVertical,
  message: initializedMessage,
  open,
  handleClose,
  handleOpen,
});

const SnackbarReducer = (
  state: SnackbarState,
  { type, payload }: SnackbarAction
) => {
  switch (type) {
    case SnackbarActionType.OPEN:
      return {
        ...state,
        ...payload,
        open: true,
      };
    case SnackbarActionType.CLOSE:
      return {
        ...state,
        ...payload,
        open: false,
      };
    default:
      return state;
  }
};

interface SnackbarContextProviderProps {
  children: ReactNode;
}

export const SnackbarContextProvider = ({
  children,
}: SnackbarContextProviderProps) => {
  const [{ horizontal, vertical, open, message }, dispatch] = useReducer(
    SnackbarReducer,
    initializedSnackbarStateValues
  );

  const handleClose = () =>
    dispatch({
      type: SnackbarActionType.CLOSE,
      payload: {
        horizontal: 'center',
        vertical: 'top',
        message,
      },
    });

  const handleOpen: SnackbarContextInterface['handleOpen'] = (
    message,
    horizontal = 'center',
    vertical = 'top',
  ) =>
    dispatch({
      type: SnackbarActionType.OPEN,
      payload: {
        horizontal,
        vertical,
        message,
      },
    });
  return (
    <SnackbarContext.Provider
      value={{ horizontal, vertical, open, message, handleClose, handleOpen }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbarContext = () => useContext(SnackbarContext);
