import Web3 from 'web3';

export const connectToMetaMask = async (): Promise<string[]> => {
  try {
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      throw Error('connectToMetaMask error: Metamask not installed');
    }

    const account = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });

    return account;
  } catch (error: any) {
    throw Error(error);
  }
};

export const requestConnectedWalletAccountFromMetaMask = async (
  provider: Web3 | null
): Promise<string> => {
  try {
    if (!provider) {
      throw Error(
        'requestConnectedWalletAccountFromMetaMask error: Provider not provided'
      );
    }

    const account = await provider.eth.getAccounts();

    if (!Array.isArray(account) || !(account.length > 0)) {
      throw Error('requestChainIdFromMetaMask error: getAcounts error');
    }

    return account[0];
  } catch (error: any) {
    throw Error(error);
  }
};

export const requestChainIdFromMetaMask = async (
  provider: Web3 | null
): Promise<number> => {
  try {
    if (!provider) {
      throw Error('requestChainIdFromMetaMask error: Provider not provided');
    }

    return await provider.eth.net.getId();
  } catch (error: any) {
    throw Error(error);
  }
};

export const requestNetworkFromMetaMask = async (
  provider: Web3 | null
): Promise<string> => {
  try {
    if (!provider) {
      throw Error('requestNetworkFromMetaMask error: Provider not provided');
    }

    return await provider.eth.net.getNetworkType();
  } catch (error: any) {
    throw Error(error);
  }
};
