import { ReactNode } from 'react';
import styled from 'styled-components';

interface MainBackgroundProps {
  children: ReactNode;
  backgroundColor?: string;
  backgroundImage?: string;
  className?: string;
}

interface MainBackgroundPropsContainer
  extends Omit<MainBackgroundProps, 'children'> {}

const OuterLayoutContainer = styled.section`
  background-color: ${({ backgroundColor }: MainBackgroundPropsContainer) =>
    backgroundColor};
  background-image: ${({ backgroundImage }: MainBackgroundPropsContainer) =>
    backgroundImage};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const MainBackground = ({
  children,
  backgroundColor = 'transparent',
  backgroundImage = 'none',
  className = '',
}: MainBackgroundProps) => (
  <OuterLayoutContainer
    className={className}
    backgroundColor={backgroundColor}
    backgroundImage={backgroundImage}
  >
    {children}
  </OuterLayoutContainer>
);
