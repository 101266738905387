import { useMetaMaskContext } from 'shared/contexts/metamask.contexts';
import Web3 from 'web3';
import {
  addressToCoinGeckoId,
  coinSymbols,
  WBTCAddress,
} from 'shared/tokens/tokens';
import { useGetPriceOfCoinsVsCurrencies } from './apis/services/mereoServer/useGetPriceOfCoinVsCurrencies';
import { useGetBasketBalanceFromContract } from './contracts/queries/useGetBasketBalanceFromContract';
import { Currency } from '../enums/currency.enums';

export const useTotalBasketAmountInUsd = () => {
  const { socketContract } = useMetaMaskContext();
  const { data: basketBalanceFromContract = null } =
    useGetBasketBalanceFromContract(socketContract);
  const { data: coinPricesToUsd = {} } = useGetPriceOfCoinsVsCurrencies(
    coinSymbols.join(','),
    Currency.USD
  );

  return (basketBalanceFromContract || []).reduce(
    (accumulator: number, currentValue: [string, string]) => {
      const address = currentValue[0];
      if (address === '0x0000000000000000000000000000000000000000')
        return accumulator;

      const coinGeckoId = addressToCoinGeckoId[address];
      const usdRate =
        (coinPricesToUsd &&
          coinPricesToUsd[coinGeckoId] &&
          coinPricesToUsd[coinGeckoId][Currency.USD]) ||
        0;
      const isWBTCAddress = currentValue[0] === WBTCAddress;
      const decimalFactor = isWBTCAddress
        ? Math.pow(10, -8)
        : Math.pow(10, -18);
      return (
        accumulator +
        +Web3.utils.toBN(currentValue[1]) * decimalFactor * usdRate
      );
    },
    0
  );
};
