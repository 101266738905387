import { AxiosError } from 'axios';
import { useSnackbarContext } from 'shared/contexts/snackbar.contexts';

interface Props {
  isLoading: boolean;
  error: AxiosError | Error | null;
  children?: React.ReactElement | null;
  shouldShowLoader?: boolean;
  CustomLoader?: () => React.ReactElement;
}

export const GenericAPIWrapper = ({
  children,
  error,
  isLoading,
  shouldShowLoader = false,
  CustomLoader,
}: Props) => {
  const { handleOpen } = useSnackbarContext();

  if (error) {
    handleOpen('Error: ' + error.message);
    return <div>{JSON.stringify(error.message)}</div>;
  }

  if (!shouldShowLoader && isLoading) {
    return null;
  }

  if (isLoading) {
    return <>{CustomLoader ? <CustomLoader /> : <div>loading...</div>}</>;
  }

  return children ? children : null;
};
