import styled from 'styled-components';

export const TopNavContainer = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 72px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 100;

  .top-nav-content {
    display: flex;
    align-items: center;
    width: 100%;

    &-home-link {
      font-size: 16px;
      line-height: 24px;
      font-weight: 800;
      color: #38362c;
      letter-spacing: 0.05em;
      transition: all 0.2s ease-in-out;
      margin-right: 16px;

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }

    &-grow-div {
      flex-grow: 1;
    }
  }
`;
