import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { PortfolioTable } from 'shared/components/PortfolioTable/PortfolioTable.components';
import { useMetaMaskContext } from 'shared/contexts/metamask.contexts';
import { polygonMainnetMvpBasket } from 'shared/baskets/baskets';
import {
  getLatestNormalizedPerformanceData,
  totalBasketAmountInEth,
} from 'pages/dashboard/dashboardHelpers';
import { GenericAPIWrapper } from 'shared/components/apiWrappers/GenericAPIWrapper.component';
import { useMuiMenu } from 'shared/hooks/muiHelpers/useMuiMenu';
import { DashboardChart } from 'shared/components/charts/DashboardChart.components';
import { PortfolioContainer } from 'pages/dashboard/Portfolio/PortfolioContainer';
import { Transaction } from 'shared/components/Transaction/Transaction.components';
import { TransactionType } from 'shared/enums/transaction.enums';
import { useGetBasketBalanceFromContract } from 'shared/hooks/contracts/queries/useGetBasketBalanceFromContract';

import MEREO_MARK_LOGO from 'assets/images/logos/mereo-mark-logo.png';
import { useGetEthBalanceFromAccount } from 'shared/hooks/contracts/queries/useGetEthBalanceFromAccount';
import { useGetRiskScore } from 'shared/hooks/contracts/queries/useGetRiskScore';
import { useGetPerformance } from 'shared/hooks/contracts/queries/useGetPerformance';
import { useWithdraw } from 'shared/hooks/contracts/mutations/useWithdraw';
import { useDeposit } from 'shared/hooks/contracts/mutations/useDeposit';
import { useYTDReturn } from 'shared/hooks/useYTDReturn';
import { useGetPriceOfCoinsVsCurrencies } from 'shared/hooks/apis/services/mereoServer/useGetPriceOfCoinVsCurrencies';
import { useTotalBasketAmountInUsd } from 'shared/hooks/useTotalBasketAmountInUsd';
import { coinSymbols } from 'shared/tokens/tokens';
import { Currency } from '../../../shared/enums/currency.enums';

export const Portfolio = () => {
  const { contract, socketContract, accountAddress, provider } =
    useMetaMaskContext();
  const {
    data: basketBalanceFromContract = null,
    isLoading: isLoadingBasketBalanceFromContract,
    error: basketBalanceFromContractError,
  } = useGetBasketBalanceFromContract(socketContract);
  const {
    data: ethBalanceFromAccount = null,
    isLoading: isLoadingEthBalanceFromAccount,
    error: ethBalanceFromAccountError,
  } = useGetEthBalanceFromAccount(provider, accountAddress);
  const {
    data: riskScore = null,
    isLoading: isLoadingRiskScore,
    error: riskScoreError,
  } = useGetRiskScore(socketContract, accountAddress);
  const {
    data: rawPerformanceData = null,
    isLoading: isLoadingRawPerformanceData,
    error: rawPerformanceDataError,
  } = useGetPerformance(socketContract);
  const [transactionType, setTransactionType] = useState<TransactionType>(
    TransactionType.DEPOSIT
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const openModal = (transactionType: TransactionType) => {
    setTransactionType(transactionType);
    setIsModalOpen(true);
  };
  const {
    data: coinPricesToUsd,
    isLoading: isLoadingCoinPricesToUsd,
    error: coinPricesToUsdError,
  } = useGetPriceOfCoinsVsCurrencies(coinSymbols.join(','), Currency.USD);
  const totalBasketAmountInUsd = useTotalBasketAmountInUsd();
  const { amount: YTDAmount, percent: YTDPercent } = useYTDReturn(coinSymbols);
  // const allTimeDeposits = useAllTimeDeposits();

  const {
    muiMenuAnchorEl,
    muiMenuOpen,
    handleMuiMenuClick,
    handleMuiMenuClose,
  } = useMuiMenu();

  const allDataIsLoading =
    isLoadingCoinPricesToUsd ||
    isLoadingBasketBalanceFromContract ||
    isLoadingEthBalanceFromAccount ||
    isLoadingRiskScore ||
    isLoadingRawPerformanceData;
  const allErrors =
    coinPricesToUsdError ||
    basketBalanceFromContractError ||
    ethBalanceFromAccountError ||
    riskScoreError ||
    rawPerformanceDataError;
  const allData =
    !!coinPricesToUsd &&
    (basketBalanceFromContract !== undefined ||
      basketBalanceFromContract !== null) &&
    !!ethBalanceFromAccount &&
    riskScore &&
    rawPerformanceData;

  const latestPerformanceData =
    getLatestNormalizedPerformanceData(rawPerformanceData, riskScore) || null;

  return (
    <PortfolioContainer>
      <GenericAPIWrapper isLoading={allDataIsLoading} error={allErrors}>
        {allData ? (
          <div className="dashboard-portfolio-content">
            <Typography
              className="dashboard-portfolio-header"
              variant="subtitle1"
            >
              Basket Performance
            </Typography>
            <div className="dashboard-overall-performance-row">
              <div className="dashboard-overall-performance-item">
                <Typography
                  className="dashboard-overall-performance-item-caption"
                  variant="caption"
                >
                  Balance
                </Typography>
                <Typography
                  className="dashboard-overall-performance-item-value"
                  variant="h5"
                >
                  ${(totalBasketAmountInUsd || 0).toFixed(2)}
                </Typography>
              </div>
              <div className="dashboard-overall-performance-item">
                <Typography
                  className="dashboard-overall-performance-item-caption"
                  variant="caption"
                >
                  YTD Return
                </Typography>
                <div className="dashboard-overall-performance-item-content">
                  <Typography
                    className="dashboard-overall-performance-item-value"
                    variant="h5"
                  >
                    {YTDAmount}
                  </Typography>
                  <Typography
                    className="dashboard-overall-performance-item-ytd-delta"
                    variant="caption"
                  >
                    {YTDPercent}
                  </Typography>
                </div>
              </div>
              <div className="flex-grow-div" />
              <div className="dashboard-overall-performance-item">
                <Button
                  className="dashboard-overall-performance-item-options-button"
                  variant="outlined"
                  id="options-button"
                  aria-controls={muiMenuOpen ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={muiMenuOpen ? 'true' : undefined}
                  onClick={handleMuiMenuClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Actions
                </Button>
                <Menu
                  id="deposit-menu"
                  anchorEl={muiMenuAnchorEl}
                  open={muiMenuOpen}
                  onClose={handleMuiMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleMuiMenuClose();
                      openModal(TransactionType.DEPOSIT);
                    }}
                  >
                    Deposit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMuiMenuClose();
                      openModal(TransactionType.WITHDRAW);
                    }}
                  >
                    Withdraw
                  </MenuItem>
                </Menu>
                <Modal
                  open={isModalOpen}
                  onClose={closeModal}
                  aria-labelledby="create-risk-assessment-modal"
                  aria-describedby="create-risk-assessment-modal"
                >
                  <Box
                    sx={{
                      position: 'absolute' as 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      display: 'flex',
                      flexDirection: 'column',
                      width: 600,
                      height: 316,
                      bgcolor: 'white',
                      boxShadow: 24,
                      p: '8px',
                      border: 'none',
                      borderRadius: '2px',
                    }}
                  >
                    <Transaction
                      contract={contract}
                      accountAddress={accountAddress}
                      balance={
                        transactionType === TransactionType.DEPOSIT
                          ? ethBalanceFromAccount
                          : totalBasketAmountInEth(
                              basketBalanceFromContract,
                              coinPricesToUsd
                            ).toString()
                      }
                      transactionType={transactionType}
                      closeModal={closeModal}
                      mutation={
                        transactionType === TransactionType.DEPOSIT
                          ? useDeposit
                          : useWithdraw
                      }
                      provider={provider}
                    />
                  </Box>
                </Modal>
              </div>
            </div>
            {!!latestPerformanceData ? (
              <DashboardChart
                rawPerformanceData={rawPerformanceData}
                realtimeValue={totalBasketAmountInUsd}
              />
            ) : (
              <div className="dashboard-chart-default">
                <img src={MEREO_MARK_LOGO} alt="Mereo mark logo" />
                <div>
                  <Typography
                    className="dashboard-chart-default-subtitle"
                    variant="subtitle1"
                  >
                    Thank you for starting this journey with us!
                  </Typography>
                  <Typography
                    className="dashboard-chart-default-caption"
                    variant="caption"
                  >
                    Your portfolio’s performance will shown here once the
                    investments are active. Please allow 1-2 days for it to be
                    fully invested.
                  </Typography>
                </div>
              </div>
            )}
            <Typography
              className="dashboard-allocation-header"
              variant="subtitle1"
            >
              Your Basket Target Allocation
            </Typography>
            <PortfolioTable
              riskScore={riskScore}
              basket={polygonMainnetMvpBasket}
            />
          </div>
        ) : (
          <></>
        )}
      </GenericAPIWrapper>
    </PortfolioContainer>
  );
};
