import { useMutation, UseMutationResult } from 'react-query';

import { queryClient } from 'AppWrapper';

import { ServerStateKey } from 'shared/enums/serverStateKey.enums';
import { withdrawAll } from 'shared/contract/contractHelpers';
import { WithdrawAllRequest } from 'shared/interfaces/contracts/withdrawAll.interfaces';

export const useWithdrawAll = (): UseMutationResult<
  void,
  Error,
  WithdrawAllRequest
> =>
  useMutation<void, Error, WithdrawAllRequest>(
    ({ contract, accountAddress, provider }) =>
      withdrawAll(contract, accountAddress, provider),
    {
      onSettled: (_, __, variables) => {
        return Promise.all([
          queryClient.refetchQueries([
            ServerStateKey.ETH_BALANCE_FROM_ACCOUNT,
            variables.accountAddress,
          ]),
          queryClient.refetchQueries([ServerStateKey.PERFORMANCE_DATA]),
          queryClient.refetchQueries([
            ServerStateKey.BASKET_BALANCE_FROM_CONTRACT,
          ]),
        ]);
      },
    }
  );
