import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { ServerStateKey } from 'shared/enums/serverStateKey.enums';
import { getBulkCoinMarketChart } from 'shared/services/mereoServer/apis.mereoServer.queries';
import { V2CoinMarketChartResponse } from '../../../../interfaces/mereoServer/getCoinHistoryByDate.interfaces';

interface GetBulkCoinMarketChartConfig {
  ids: string;
  vsCurrency: string;
  days: string;
  interval?: string;
}

export const useGetBulkCoinMarketChart = ({
  ids,
  vsCurrency,
  days,
  interval,
}: GetBulkCoinMarketChartConfig): UseQueryResult<
  V2CoinMarketChartResponse,
  AxiosError<any>
> => {
  return useQuery<V2CoinMarketChartResponse, AxiosError>(
    [
      ServerStateKey.V2_COIN_MARKET_CHART,
      ids,
      vsCurrency,
      days,
      interval,
    ],
    () => getBulkCoinMarketChart(ids, vsCurrency, days, interval),
    {
      enabled: !!ids && !!vsCurrency && !!days && !!interval,
    }
  );
};
