import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import {
  fullPerformanceData,
  hydrateChartData,
} from 'pages/dashboard/dashboardHelpers';
import { useState } from 'react';
import { useGetBulkCoinMarketChart } from 'shared/hooks/apis/services/mereoServer/useGetBulkCoinMarketChart';
import { addressToCryptoWatchId, coinSymbols } from 'shared/tokens/tokens';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { PolygonMainnetToken } from '../../enums/token.enums';
import { PolygonMainnetMvpBasketData } from '../../baskets/baskets';
import { format, sub } from 'date-fns';
import { useGetBulkCoinHistoryByDate } from '../../hooks/apis/services/mereoServer/useGetBulkCoinHistoryByDate';
import { Currency } from '../../enums/currency.enums';

const ButtonRow = styled.section`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

interface Props {
  rawPerformanceData?: any;
  realtimeValue?: number;
  basket?: {
    [key in PolygonMainnetToken]: PolygonMainnetMvpBasketData;
  }
}

const priceFormatter = (value: string) => `$${value}`;

export const enum INTERVAL {
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
}

const enum OPTION_ID {
  ONE = 'one',
  TWO = 'two',
  THREE = 'three',
  FOUR = 'four',
  FIVE = 'five',
}

export interface IDisplayOptions {
  id: OPTION_ID;
  label: string;
  days: number;
  interval: INTERVAL;
}

const displayOptions = [
  {
    id: OPTION_ID.ONE,
    label: '1 week',
    quantity: 7,
    interval: INTERVAL.DAILY,
  },
  {
    id: OPTION_ID.TWO,
    label: '1 month',
    quantity: 30,
    interval: INTERVAL.DAILY,
  },
  {
    id: OPTION_ID.THREE,
    label: '3 months',
    quantity: 12,
    interval: INTERVAL.WEEKLY,
  },
  {
    id: OPTION_ID.FOUR,
    label: '6 months',
    quantity: 26,
    interval: INTERVAL.WEEKLY,
  },
  {
    id: OPTION_ID.FIVE,
    label: '1 year',
    quantity: 52,
    interval: INTERVAL.WEEKLY,
  },
  // {
  //   label: 'All Time',
  //   days: ,
  //   interval: INTERVAL.DAILY,
  // },
];

const convertToDays = (quantity: number, interval: INTERVAL): number => {
  if (interval === INTERVAL.DAILY) return quantity;
  if (interval === INTERVAL.WEEKLY) return quantity * 7;
  return quantity;
};

export const DashboardChart = ({
  rawPerformanceData,
  realtimeValue,
  basket,
}: Props) => {
  const [id, setId] = useState(OPTION_ID.ONE);
  const selectedOption =
    displayOptions.find(({ id: optionId }) => optionId === id) ||
    displayOptions[0];
  const { quantity, interval } = selectedOption;
  const { data: coinMarketChartData } = useGetBulkCoinMarketChart({
    ids: coinSymbols.join(','),
    vsCurrency: Currency.USD,
    days: String(convertToDays(selectedOption.quantity, interval)),
    interval: selectedOption.interval,
  });

  const startingFromDay = new Date();
  const subtractionMap = {
    [INTERVAL.HOURLY]: { hours: quantity },
    [INTERVAL.DAILY]: { days: quantity },
    [INTERVAL.WEEKLY]: { weeks: quantity },
  };
  const leftDate = sub(startingFromDay, subtractionMap[interval]);
  const { data: bulkCoinHistoryByDateData } = useGetBulkCoinHistoryByDate({
    ids: coinSymbols.join(','),
    date: format(leftDate, 'yyyy-MM-dd'),
  });

  const dummyInvestmentInUsd = 1000; // 1000 dollars
  const usdUnitPrice =
    bulkCoinHistoryByDateData?.['matic']?.[Currency.USD] || 0;
  const dummyInvestmentInNetworkTokenWei = dummyInvestmentInUsd / usdUnitPrice;

  const dummyInitialInvestment = basket && Object.keys(basket).reduce((acc, curr) => {
    const { address, allocation } = basket[curr as PolygonMainnetToken];
    const id = addressToCryptoWatchId[address];
    const tokenToUsdPrice = (bulkCoinHistoryByDateData && bulkCoinHistoryByDateData[id]?.[Currency.USD]) || 0;
    const isWBTC = id === 'btc';
    const decimalFactor = isWBTC ? Math.pow(10, 8) : Math.pow(10, 18);
    const networkTokenAllocated =
      dummyInvestmentInNetworkTokenWei * allocation * decimalFactor;
    const networkTokenAllocatedInUsd =
      networkTokenAllocated * usdUnitPrice;
    const tokenAmount =
      networkTokenAllocatedInUsd / tokenToUsdPrice;
    return [...acc, [address, tokenAmount, 0]];
  }, [] as any);

  const performanceData = rawPerformanceData
    ? fullPerformanceData(rawPerformanceData)
    : fullPerformanceData(dummyInitialInvestment);
  if (!coinMarketChartData) return <></>;

  const coinIdToUsdPriceHistories = Object.keys(coinMarketChartData).reduce(
    (acc, curr, index) => {
      return {
        ...acc,
        [curr]: coinMarketChartData && coinMarketChartData[curr].prices,
      };
    },
    {}
  );

  return (
    <>
      <ResponsiveContainer width="100%" height={280}>
        <LineChart
          data={hydrateChartData(
            performanceData,
            coinIdToUsdPriceHistories,
            quantity,
            interval,
            realtimeValue
          )}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" fontSize="0.75rem" />
          <YAxis tickFormatter={priceFormatter} fontSize="0.75rem" />
          <Tooltip />
          <Legend fontSize="0.875rem" />
          <Line
            type="monotone"
            dataKey="amount"
            stroke="rgba(0, 0, 0, 0.87)"
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </ResponsiveContainer>
      <ButtonRow>
        {displayOptions.map(({ id: currId, label }) => (
          <Button
            variant="outlined"
            disabled={id === currId}
            key={currId}
            onClick={() => setId(currId)}
          >
            {label}
          </Button>
        ))}
      </ButtonRow>
    </>
  );
};
