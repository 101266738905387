import { CreateAccountRequest } from 'shared/interfaces/contracts/createAccount.interfaces';
import Web3 from 'web3';
import { Contract, EventData } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';
import { ContractEvent } from '../enums/event.enums';

export const createContract = (
  provider: Web3 | null,
  accountAddress: string | null,
  ABI: AbiItem[],
  contractAddress: string
) => {
  try {
    if (!provider || !accountAddress || !ABI || !contractAddress) {
      throw Error(
        'createContract error: provider, accountAddress, ABI, or contractAddress is not provided'
      );
    }

    return new provider.eth.Contract(ABI, contractAddress, {
      from: accountAddress,
    });
  } catch (error: any) {
    throw Error(error);
  }
};

export const createAccount = async ({
  contract,
  accountAddress,
  depositValue,
  riskScore,
  provider,
}: CreateAccountRequest) => {
  try {
    if (
      !contract ||
      !accountAddress ||
      depositValue == null ||
      !riskScore ||
      !provider
    ) {
      throw Error(
        'createAccount error: contract, accountAddress, depositValue, riskScore, or provider is not provided'
      );
    }
    const depositAsWei = Web3.utils.toWei(depositValue.toString());
    const gasPrice = await provider.eth.getGasPrice();

    await contract.methods.createAccount(depositAsWei, riskScore - 1).send({
      from: accountAddress,
      value: depositAsWei,
      gasPrice,
    });
  } catch (error: any) {
    throw error;
  }
};

export const getEthBalanceFromAccount = async (
  provider: Web3 | null,
  accountAddress: string | null
): Promise<string | null> => {
  try {
    if (!provider || !accountAddress) {
      throw Error(
        'getEthBalanceFromAccount error: provider or accountAddress is not provided'
      );
    }

    return provider.utils.fromWei(
      await provider.eth.getBalance(accountAddress)
    );
  } catch (error: any) {
    throw Error(`getEthBalanceFromAccount error: ${error.message}`);
  }
};

export const getBasketBalanceFromContract = async (
  contract: Contract | null
): Promise<[string, string][] | null> => {
  try {
    if (!contract) {
      throw Error(
        'getBasketBalanceFromContract error: contract is not provided'
      );
    }

    return await contract.methods.getBalance().call();
  } catch (error: any) {
    throw Error(`getBasketBalanceFromContract error: ${error.message}`);
  }
};

export const getRiskScore = async (
  contract: Contract | null,
  accountAddress: string | null
): Promise<string | null> => {
  try {
    if (!contract || !accountAddress) {
      throw Error(
        'getRiskScore error: contract or accountAddress is not provided'
      );
    }

    return await contract.methods.riskScoreOf(accountAddress).call();
  } catch (error: any) {
    throw Error(error);
  }
};

export const getPerformance = async (
  contract: Contract | null
): Promise<[string, string, string][] | null> => {
  try {
    if (!contract) {
      throw Error('getPerformance error: contract is not provided');
    }

    return await contract.methods.getPerformance().call();
  } catch (error: any) {
    throw Error(error);
  }
};

export const getUserBasketAddress = async (
  contract: Contract | null,
  accountAddress: string | null
): Promise<string | null> => {
  try {
    if (!contract || !accountAddress) {
      throw Error(
        'getUserBasketAddress error: contract or accountAddress is not provided'
      );
    }

    return contract.methods.getUserBasket(accountAddress).call();
  } catch (error: any) {
    throw Error(error);
  }
};

export const deposit = async (
  contract: Contract | null,
  accountAddress: string | null,
  value: string | null,
  provider: Web3 | null
): Promise<void> => {
  try {
    if (!contract || !accountAddress || value == null || !provider) {
      throw Error(
        'deposit error: contract, provider, value, or accountAddress is not provided'
      );
    }

    const valueAsWei = Web3.utils.toWei(value.toString());
    const gasPrice = await provider.eth.getGasPrice();

    await contract.methods.deposit().send({
      from: accountAddress,
      value: valueAsWei,
      gasPrice,
    });
  } catch (error: any) {
    throw Error(error);
  }
};

export const withdraw = async (
  contract: Contract | null,
  accountAddress: string | null,
  value: string | null,
  provider: Web3 | null
): Promise<void> => {
  try {
    if (!contract || !accountAddress || value == null || !provider) {
      throw Error(
        'withdraw error: contract, provider, value, or accountAddress is not provided'
      );
    }

    const valueAsWei = Web3.utils.toWei(value.toString());
    const gasPrice = await provider.eth.getGasPrice();

    await contract.methods.withdraw(valueAsWei).send({
      from: accountAddress,
      gasPrice,
    });
  } catch (error: any) {
    throw Error(error);
  }
};

export const withdrawAll = async (
  contract: Contract | null,
  accountAddress: string | null,
  provider: Web3 | null
): Promise<void> => {
  try {
    if (!contract || !accountAddress || !provider) {
      throw Error(
        'withdraw error: contract, provider, or accountAddress is not provided'
      );
    }

    const gasPrice = await provider.eth.getGasPrice();

    await contract.methods.withdrawAll().send({
      from: accountAddress,
      gasPrice,
    });
  } catch (error: any) {
    throw Error(error);
  }
};

export const getPastEventsFromContract = async (
  provider: Web3 | null,
  contract: Contract | null,
  accountAddress: string | null,
  event: ContractEvent,
): Promise<EventData[] | null> => {
  if (!contract || !accountAddress || !provider) {
    throw Error(
      'getPastEventsFromContract: contract, provider, or accountAddress is not provided'
    );
  }
  const fromBlockNumber = 0;
  const toBlockNumber = +(await provider.eth.getBlockNumber());
  const totalBlocks = toBlockNumber - fromBlockNumber
  const chunks = []
  const chunkLimit = 10000;
  if (chunkLimit > 0 && totalBlocks > chunkLimit) {
    const count = Math.ceil(totalBlocks / chunkLimit)
    let startingBlock = fromBlockNumber

    for (let index = 0; index < count; index++) {
      const fromRangeBlock = startingBlock
      const toRangeBlock =
        index === count - 1 ? toBlockNumber : startingBlock + chunkLimit
      startingBlock = toRangeBlock + 1

      chunks.push({ fromBlock: fromRangeBlock, toBlock: toRangeBlock })
    }
  } else {
    chunks.push({ fromBlock: fromBlockNumber, toBlock: toBlockNumber })
  }

  const events: EventData[] = [];
  const errors: any = [];
  for (const chunk of chunks) {
    await contract?.getPastEvents(
      event,
      {
        fromBlock: chunk.fromBlock,
        toBlock: chunk.toBlock
      },
      (error, chunkEvents: any) => {
        if (chunkEvents?.length > 0) {
          events.push(...chunkEvents)
        }

        if (error) errors.push(error)
      }
    )
  }

  return events;
}
