import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import { TopNavContainer } from 'shared/components/TopNav/TopNavContainer';
import { AuthenticatedTopNav } from 'shared/components/TopNav/AuthenticatedTopNav.components';
import { useMetaMaskContext } from 'shared/contexts/metamask.contexts';

export const TopNav = () => {
  const { accountAddress, provider, connectMetaMaskWalletHandler } =
    useMetaMaskContext();
  return (
    <TopNavContainer>
      <div className="top-nav-content">
        <Typography
          className="top-nav-content-home-link"
          onClick={() => (window.location.href = 'https://mereo.app')}
        >
          mereo.app
        </Typography>
        <Alert severity="info">
          This application requires the installation and use of MetaMask
        </Alert>
        <div className="top-nav-content-grow-div" />
        {!!accountAddress && !!provider ? (
          <AuthenticatedTopNav
            accountAddress={accountAddress}
            provider={provider}
          />
        ) : (
          <Button variant="outlined" onClick={connectMetaMaskWalletHandler}>
            Connect wallet
          </Button>
        )}
      </div>
    </TopNavContainer>
  );
};
