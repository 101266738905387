import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { UseMutationResult } from 'react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Contract } from 'web3-eth-contract';
import Web3 from 'web3';

import POLYGON_MATIC_LOGO from 'assets/images/logos/polygon-matic-logo.png';
import { TransactionContainer } from 'shared/components/Transaction/TransactionContainer';
import { TransactionType } from 'shared/enums/transaction.enums';
import { TransactionRequest } from 'shared/interfaces/contracts/transaction.interfaces';
import { useWithdrawAll } from 'shared/hooks/contracts/mutations/useWithdrawAll';

interface FormSchema {
  value: string;
}

const createInitialValues = () => ({
  value: '0.0',
});

const schema = yup
  .object({
    value: yup
      .string()
      .matches(
        /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
        'Only numbers and decimals allowed'
      ),
  })
  .required();

interface Props {
  contract: Contract | null;
  accountAddress: string | null;
  balance: string | null;
  transactionType: TransactionType;
  closeModal: () => void;
  mutation: () => UseMutationResult<void, Error, TransactionRequest>;
  provider: Web3 | null;
}

export const Transaction = ({
  accountAddress,
  contract,
  balance,
  transactionType,
  closeModal,
  mutation,
  provider,
}: Props) => {
  const [isWithdrawAll, setWithrawAll] = useState(false);
  const { isLoading: isLoadingTransact, mutateAsync: transact } = mutation();
  const { isLoading: isLoadingWithdrawAll, mutateAsync: withdrawAll } =
    useWithdrawAll();
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isValid, isDirty },
    reset,
    setValue,
  } = useForm<FormSchema>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      ...createInitialValues(),
    },
  });

  const handleClickWithdrawAll = () => {
    setValue('value', '0.0');
    setWithrawAll(true);
  };

  const handleFormSubmit = async ({ value }: FormSchema) => {
    try {
      if (isWithdrawAll) {
        await withdrawAll({ contract, accountAddress, provider });
      } else {
        await transact({ contract, accountAddress, value, provider });
      }
      clearErrors();
      reset();
      closeModal();
    } catch (error) {
      console.log(error);
    } finally {
      setWithrawAll(false);
    }
  };

  return (
    <TransactionContainer>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="transaction-header-container">
          <Typography variant="h6">
            {transactionType === TransactionType.DEPOSIT
              ? 'Deposit'
              : 'Withdraw'}
          </Typography>
        </div>
        <div className="transaction-content">
          <Typography
            className="transaction-content-fund-header"
            variant="subtitle1"
          >
            How much would you like to{' '}
            {transactionType === TransactionType.DEPOSIT
              ? 'deposit'
              : 'withdraw'}
            ?
          </Typography>
          <TextField
            className="transaction-content-input"
            type="number"
            helperText={
              balance
                ? `${balance} MATIC available to ${
                    transactionType === TransactionType.DEPOSIT
                      ? 'deposit'
                      : 'withdraw'
                  }`
                : 'Wallet not connected'
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div className="transaction-content-input-adornment">
                    <img src={POLYGON_MATIC_LOGO} alt="Polygon Matic logo" />
                    <Typography className="transaction-content-input-text">
                      MATIC
                    </Typography>
                  </div>
                </InputAdornment>
              ),
            }}
            inputProps={{ ...register('value'), step: 'any', min: 0 }}
          />
        </div>
        <div className="transaction-button-row">
          <Button
            className="transaction-next-button"
            type="submit"
            disabled={isLoadingTransact || isLoadingWithdrawAll}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            className="transaction-next-button"
            variant="outlined"
            type="submit"
            disabled={
              !isValid || !isDirty || isLoadingTransact || isLoadingWithdrawAll
            }
          >
            {isLoadingTransact || isLoadingWithdrawAll
              ? 'Loading...'
              : transactionType === TransactionType.DEPOSIT
              ? 'Deposit'
              : 'Withdraw'}
          </Button>
          {transactionType === TransactionType.WITHDRAW ? (
            <Button
              className="transaction-next-button"
              variant="outlined"
              type="submit"
              onClick={handleClickWithdrawAll}
              disabled={isLoadingTransact || isLoadingWithdrawAll}
            >
              {isLoadingTransact || isLoadingWithdrawAll
                ? 'Loading...'
                : 'Withdraw All'}
            </Button>
          ) : null}
        </div>
        {errors?.value ? (
          <Typography className="transaction-error-text">
            {errors.value.message}
          </Typography>
        ) : (
          <></>
        )}
      </form>
    </TransactionContainer>
  );
};
