import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useMetaMaskContext } from 'shared/contexts/metamask.contexts';
import { useGetUserBasketAddress } from '../../hooks/contracts/queries/useGetUserBasketAddress';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let ethereum = window.ethereum;

interface AuthenticatedRouteProps {
  children: ReactNode;
}

export const AuthenticatedRoute = ({ children }: AuthenticatedRouteProps) => {
  const { accountAddress, socketContract } = useMetaMaskContext();
  const { data: userBasketAddress } = useGetUserBasketAddress(
    socketContract,
    accountAddress
  );
  const { pathname } = useLocation();
  const userHasNoBasket =
    userBasketAddress === '0x0000000000000000000000000000000000000000' ||
    !userBasketAddress;

  if (
    (!ethereum || !ethereum.isMetaMask) &&
    pathname !== '/create/risk-assessment'
  ) {
    window.open('https://metamask.io/', '_blank')?.focus();
    return <Navigate to={'/create/risk-assessment'} replace={true} />;
  } else if (
    (!ethereum || !ethereum.isMetaMask) &&
    pathname === '/create/risk-assessment'
  ) {
    window.open('https://metamask.io/', '_blank')?.focus();
  }

  if (userHasNoBasket && pathname === '/dashboard/portfolio') {
    console.log('userHasNoBasket && pathname === /dashboard/portfolio');
    return <Navigate to={'/create/risk-assessment'} replace={true} />;
  }

  if (!userHasNoBasket && pathname !== '/dashboard/portfolio' && pathname !== '/congratulations') {
    return <Navigate to={'/dashboard/portfolio'} replace={true} />;
  }

  if (userHasNoBasket && pathname === '/create/connect' && accountAddress) {
    return <Navigate to={'/create/deposit'} replace={true} />;
  }

  return <>{children}</>;
};
