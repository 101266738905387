import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { WalletCard } from 'shared/components/WalletCard/WalletCard.components';
import { useMetaMaskContext } from 'shared/contexts/metamask.contexts';

import METAMASK_LOGO from 'assets/images/logos/metamask-logo.png';
import TRUST_WALLET_LOGO from 'assets/images/logos/trust-wallet-logo.png';
import COINBASE_LOGO from 'assets/images/logos/coinbase-logo.png';
import MATH_WALLET_LOGO from 'assets/images/logos/math-wallet-logo.png';
import TOKEN_POCKET_LOGO from 'assets/images/logos/token-pocket-logo.png';
import UNKNOWN_LOGO from 'assets/images/logos/unknown-logo.png';
import { useSnackbarContext } from 'shared/contexts/snackbar.contexts';

const walletMap = [
  {
    logo: TRUST_WALLET_LOGO,
    name: 'Trust Wallet',
  },
  {
    logo: COINBASE_LOGO,
    name: 'Coinbase Wallet',
  },
  {
    logo: MATH_WALLET_LOGO,
    name: 'Math Wallet',
  },
  {
    logo: TOKEN_POCKET_LOGO,
    name: 'Tokenpocket',
  },
  {
    logo: UNKNOWN_LOGO,
    name: 'Unknown Wallet',
  },
];

const ConnectContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  h4 {
    margin-bottom: 32px;
  }

  .connect-wallet-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 100%;
    padding: 40px 64px 52px;

    &-header {
      margin-bottom: 16px;
    }

    &-metamask {
      margin-bottom: 40px;
    }

    &-divider {
      width: 100%;
      margin-bottom: 28px;
    }

    &-coming-soon {
      margin-bottom: 8px;
    }

    &-follow-release {
      margin-bottom: 40px;
    }

    &-disabled-wallets {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 452px;

      > div {
        margin-bottom: 24px;
      }
    }

    .connect-wallet-button {
      margin-bottom: 40px;

      &-icon {
        margin-left: 8px;
      }
    }
  }
`;

export const Connect = () => {
  const navigate = useNavigate();
  const { connectMetaMaskWalletHandler } = useMetaMaskContext();
  const { handleOpen } = useSnackbarContext();

  const handleConnectMetaMaskWallet = async () => {
    try {
      await connectMetaMaskWalletHandler();
      navigate('/create/deposit');
    } catch (error: any) {
      handleOpen('Error: ' + error.message);
    }
  };

  return (
    <ConnectContainer>
      <Typography variant="h4">
        Connect your wallet to enable trading
      </Typography>
      <div className="connect-wallet-content">
        <Typography
          className="connect-wallet-content-header"
          variant="subtitle1"
        >
          Available Wallet
        </Typography>
        <WalletCard
          className="connect-wallet-content-metamask"
          src={METAMASK_LOGO}
          alt="metamask-logo"
          onClick={handleConnectMetaMaskWallet}
        >
          Metamask
        </WalletCard>
        <Divider className="connect-wallet-content-divider" />
        <Typography
          className="connect-wallet-content-coming-soon"
          variant="subtitle1"
        >
          Coming soon...
        </Typography>
        <Typography
          className="connect-wallet-content-follow-release"
          variant="body1"
        >
          Follow our release note to stay updated on our new features
        </Typography>
        <div className="connect-wallet-content-disabled-wallets">
          {walletMap.map(({ logo, name }) => (
            <WalletCard key={name} src={logo} disabled>
              {name}
            </WalletCard>
          ))}
        </div>
      </div>
    </ConnectContainer>
  );
};
