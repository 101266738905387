import styled from 'styled-components';

export const PortfolioContainer = styled.section`
  .dashboard-portfolio-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: inherit;
    width: 100%;
    padding: 48px 112px 44px;
  }

  .dashboard-portfolio-header {
    margin-bottom: 24px;
  }

  .dashboard-overall-performance-row {
    display: flex;
    margin-bottom: 30px;
  }

  .dashboard-overall-performance-item {
    display: flex;
    flex-direction: column;

    &:first-of-type {
      width: 30%;
    }

    &:nth-of-type(2) {
      width: 25%;
    }

    &:last-of-type {
      justify-content: flex-end;
    }

    &-caption {
      margin-bottom: 4px;
      color: rgba(0, 0, 0, 0.54);
    }

    &-content {
      display: flex;
      align-items: flex-end;
    }

    &-ytd-delta {
      margin-left: 6px;
      margin-bottom: 2px;
      color: rgba(0, 0, 0, 0.54);
    }

    &-options-button {
      padding: 6px 22px;
    }
  }

  .dashboard-chart-default {
    width: 100%;
    background-color: #fffbf4;
    padding: 72px 174px 82px 44px;
    display: flex;
    align-items: center;

    img {
      height: 92px;
      width: 80px;
      margin-right: 58px;
    }

    &-content {
      display: flex;
      flex-direction: column;
    }

    &-subtitle {
      margin-bottom: 8px;
    }

    &-caption {
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .dashboard-allocation-header {
    margin: 48px 0 24px;
  }

  .flex-grow-div {
    flex-grow: 1;
  }
`;
