import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { RiskScoreSlider } from 'shared/components/RiskScoreSlider/RiskScoreSlider.components';
import { PortfolioTable } from 'shared/components/PortfolioTable/PortfolioTable.components';
import { useRiskScoreContext } from 'shared/contexts/riskScore.contexts';
import { polygonMainnetMvpBasket } from 'shared/baskets/baskets';

const RiskAssessmentContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  h4 {
    margin-bottom: 32px;
  }

  .create-risk-assessment-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
    width: 100%;
    padding: 21px 64px 60px;

    &-risk-level-header {
      margin-bottom: 6px;
    }

    &-slider-text {
      margin-bottom: 32px;
    }

    &-slider {
      margin-bottom: 28px;
    }

    &-divider {
      margin-bottom: 21px;
    }

    &-portfolio-header {
      margin-bottom: 24px;
    }
  }

  .create-risk-assessment-risk-label {
    display flex;
    justify-content: space-between;
  }

  .create-risk-assessment-next-button {
    margin-top: 40px;

    &-icon {
      margin-left: 8px;
    }
  }
`;

export const RiskAssessment = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { riskScore } = useRiskScoreContext();

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <RiskAssessmentContainer>
      <Typography variant="h4">
        Welcome! First, let's customize your portfolio
      </Typography>
      <div className="create-risk-assessment-content">
        <Typography
          className="create-risk-assessment-content-risk-level-header"
          variant="subtitle1"
        >
          Your risk level
        </Typography>
        <Typography
          className="create-risk-assessment-content-slider-text"
          variant="body2"
        >
          Use the slider to compare portfolios at different risk levels. Choose
          the one that fits you the best.
        </Typography>
        <div className="create-risk-assessment-risk-label">
          <Typography variant="body2" fontWeight="bold">Least risk</Typography>
          <Typography variant="body2" fontWeight="bold">Most risk</Typography>
        </div>
        <RiskScoreSlider className="create-risk-assessment-content-slider" />
        <Divider className="create-risk-assessment-content-divider" />
        <Typography
          className="create-risk-assessment-content-portfolio-header"
          variant="subtitle1"
        >
          Your portfolio
        </Typography>
        <PortfolioTable
          riskScore={riskScore.toString()}
          basket={polygonMainnetMvpBasket}
        />
      </div>
      <Button
        className="create-risk-assessment-next-button"
        variant="outlined"
        onClick={handleOpen}
      >
        Next - Fund your account
        <ArrowForwardIcon className="create-risk-assessment-next-button-icon" />
      </Button>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="create-risk-assessment-modal"
        aria-describedby="create-risk-assessment-modal"
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            width: 600,
            bgcolor: 'white',
            boxShadow: 24,
            p: '8px',
            border: 'none',
            borderRadius: '2px',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginTop: '8px',
              marginX: '16px',
              marginBottom: '18px',
            }}
          >
            Are you sure?
          </Typography>
          <ErrorOutlineIcon
            sx={{
              marginLeft: '18px',
              marginBottom: '10px',
              color: '#FF9800',
              height: '20px',
              width: '20px',
            }}
          />
          <Typography
            variant="body1"
            sx={{
              marginX: '16px',
              marginBottom: '16px',
            }}
          >
            You have picked risk level {riskScore} as your portfolio. Once your
            account is active, you can only change risk level after we release
            our next version. Please refer to our roadmap for our milestones.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignSelf: 'flex-end',
            }}
          >
            <Button
              variant="text"
              sx={{
                marginRight: '16px',
              }}
              onClick={handleClose}
            >
              No, go back
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate('/create/connect')}
            >
              Yes, I'm sure
            </Button>
          </Box>
        </Box>
      </Modal>
    </RiskAssessmentContainer>
  );
};
