import { TokenCategory } from 'shared/enums/tokenCategory.enums';
import {
  EthereumMainnetToken,
  PolygonMainnetToken,
} from 'shared/enums/token.enums';
import {
  EthereumMainnetTokenAddress,
  PolygonMainnetTokenAddress,
} from 'shared/enums/tokenAddress.enums';

export interface EthereumMainnetMvpBasketData {
  allocation: number;
  address: EthereumMainnetTokenAddress;
  category: TokenCategory;
  name: EthereumMainnetToken;
}

export interface EthereumMvpBasket {
  [key: number]: {
    [key in EthereumMainnetToken]: EthereumMainnetMvpBasketData;
  };
}

export interface PolygonMainnetMvpBasketData {
  allocation: number;
  address: PolygonMainnetTokenAddress;
  category: TokenCategory;
  name: PolygonMainnetToken;
}

export interface PolygonMvpBasket {
  [key: string]: {
    [key in PolygonMainnetToken]: PolygonMainnetMvpBasketData;
  };
}

export const ethereumMainnetMvpBasket: EthereumMvpBasket = Object.freeze({
  0: {
    [EthereumMainnetToken.ETH]: {
      allocation: 0.2,
      address: EthereumMainnetTokenAddress.ETH,
      category: TokenCategory.OG,
      name: EthereumMainnetToken.ETH,
    },
    [EthereumMainnetToken.BTC]: {
      allocation: 0.2,
      address: EthereumMainnetTokenAddress.BTC,
      category: TokenCategory.OG,
      name: EthereumMainnetToken.BTC,
    },
    [EthereumMainnetToken.AXS]: {
      allocation: 0.15,
      address: EthereumMainnetTokenAddress.AXS,
      category: TokenCategory.METAVERSE,
      name: EthereumMainnetToken.AXS,
    },
    [EthereumMainnetToken.LUNA]: {
      allocation: 0.15,
      address: EthereumMainnetTokenAddress.LUNA,
      category: TokenCategory.DEFI,
      name: EthereumMainnetToken.LUNA,
    },
    [EthereumMainnetToken.SAND]: {
      allocation: 0.15,
      address: EthereumMainnetTokenAddress.SAND,
      category: TokenCategory.NFT,
      name: EthereumMainnetToken.SAND,
    },
    [EthereumMainnetToken.SOL]: {
      allocation: 0.15,
      address: EthereumMainnetTokenAddress.SOL,
      category: TokenCategory['L1/L2'],
      name: EthereumMainnetToken.SOL,
    },
  },
  1: {
    [EthereumMainnetToken.ETH]: {
      allocation: 0.1625,
      address: EthereumMainnetTokenAddress.ETH,
      category: TokenCategory.OG,
      name: EthereumMainnetToken.ETH,
    },
    [EthereumMainnetToken.BTC]: {
      allocation: 0.1625,
      address: EthereumMainnetTokenAddress.BTC,
      category: TokenCategory.OG,
      name: EthereumMainnetToken.BTC,
    },
    [EthereumMainnetToken.AXS]: {
      allocation: 0.17,
      address: EthereumMainnetTokenAddress.AXS,
      category: TokenCategory.METAVERSE,
      name: EthereumMainnetToken.AXS,
    },
    [EthereumMainnetToken.LUNA]: {
      allocation: 0.17,
      address: EthereumMainnetTokenAddress.LUNA,
      category: TokenCategory.DEFI,
      name: EthereumMainnetToken.LUNA,
    },
    [EthereumMainnetToken.SAND]: {
      allocation: 0.17,
      address: EthereumMainnetTokenAddress.SAND,
      category: TokenCategory.NFT,
      name: EthereumMainnetToken.SAND,
    },
    [EthereumMainnetToken.SOL]: {
      allocation: 0.17,
      address: EthereumMainnetTokenAddress.SOL,
      category: TokenCategory['L1/L2'],
      name: EthereumMainnetToken.SOL,
    },
  },
  2: {
    [EthereumMainnetToken.ETH]: {
      allocation: 0.125,
      address: EthereumMainnetTokenAddress.ETH,
      category: TokenCategory.OG,
      name: EthereumMainnetToken.ETH,
    },
    [EthereumMainnetToken.BTC]: {
      allocation: 0.125,
      address: EthereumMainnetTokenAddress.BTC,
      category: TokenCategory.OG,
      name: EthereumMainnetToken.BTC,
    },
    [EthereumMainnetToken.AXS]: {
      allocation: 0.19,
      address: EthereumMainnetTokenAddress.AXS,
      category: TokenCategory.METAVERSE,
      name: EthereumMainnetToken.AXS,
    },
    [EthereumMainnetToken.LUNA]: {
      allocation: 0.19,
      address: EthereumMainnetTokenAddress.LUNA,
      category: TokenCategory.DEFI,
      name: EthereumMainnetToken.LUNA,
    },
    [EthereumMainnetToken.SAND]: {
      allocation: 0.19,
      address: EthereumMainnetTokenAddress.SAND,
      category: TokenCategory.NFT,
      name: EthereumMainnetToken.SAND,
    },
    [EthereumMainnetToken.SOL]: {
      allocation: 0.19,
      address: EthereumMainnetTokenAddress.SOL,
      category: TokenCategory['L1/L2'],
      name: EthereumMainnetToken.SOL,
    },
  },
  3: {
    [EthereumMainnetToken.ETH]: {
      allocation: 0.0875,
      address: EthereumMainnetTokenAddress.ETH,
      category: TokenCategory.OG,
      name: EthereumMainnetToken.ETH,
    },
    [EthereumMainnetToken.BTC]: {
      allocation: 0.0875,
      address: EthereumMainnetTokenAddress.BTC,
      category: TokenCategory.OG,
      name: EthereumMainnetToken.BTC,
    },
    [EthereumMainnetToken.AXS]: {
      allocation: 0.21,
      address: EthereumMainnetTokenAddress.AXS,
      category: TokenCategory.METAVERSE,
      name: EthereumMainnetToken.AXS,
    },
    [EthereumMainnetToken.LUNA]: {
      allocation: 0.21,
      address: EthereumMainnetTokenAddress.LUNA,
      category: TokenCategory.DEFI,
      name: EthereumMainnetToken.LUNA,
    },
    [EthereumMainnetToken.SAND]: {
      allocation: 0.21,
      address: EthereumMainnetTokenAddress.SAND,
      category: TokenCategory.NFT,
      name: EthereumMainnetToken.SAND,
    },
    [EthereumMainnetToken.SOL]: {
      allocation: 0.21,
      address: EthereumMainnetTokenAddress.SOL,
      category: TokenCategory['L1/L2'],
      name: EthereumMainnetToken.SOL,
    },
  },
  4: {
    [EthereumMainnetToken.ETH]: {
      allocation: 0.05,
      address: EthereumMainnetTokenAddress.ETH,
      category: TokenCategory.OG,
      name: EthereumMainnetToken.ETH,
    },
    [EthereumMainnetToken.BTC]: {
      allocation: 0.05,
      address: EthereumMainnetTokenAddress.BTC,
      category: TokenCategory.OG,
      name: EthereumMainnetToken.BTC,
    },
    [EthereumMainnetToken.AXS]: {
      allocation: 0.23,
      address: EthereumMainnetTokenAddress.AXS,
      category: TokenCategory.METAVERSE,
      name: EthereumMainnetToken.AXS,
    },
    [EthereumMainnetToken.LUNA]: {
      allocation: 0.23,
      address: EthereumMainnetTokenAddress.LUNA,
      category: TokenCategory.DEFI,
      name: EthereumMainnetToken.LUNA,
    },
    [EthereumMainnetToken.SAND]: {
      allocation: 0.23,
      address: EthereumMainnetTokenAddress.SAND,
      category: TokenCategory.NFT,
      name: EthereumMainnetToken.SAND,
    },
    [EthereumMainnetToken.SOL]: {
      allocation: 0.23,
      address: EthereumMainnetTokenAddress.SOL,
      category: TokenCategory['L1/L2'],
      name: EthereumMainnetToken.SOL,
    },
  },
});

export const polygonMainnetMvpBasket: PolygonMvpBasket = Object.freeze({
  0: {
    [PolygonMainnetToken.ETH]: {
      allocation: 0.2,
      address: PolygonMainnetTokenAddress.ETH,
      category: TokenCategory.OG,
      name: PolygonMainnetToken.ETH,
    },
    [PolygonMainnetToken.BTC]: {
      allocation: 0.2,
      address: PolygonMainnetTokenAddress.BTC,
      category: TokenCategory.OG,
      name: PolygonMainnetToken.BTC,
    },
    [PolygonMainnetToken.MANA]: {
      allocation: 0.15,
      address: PolygonMainnetTokenAddress.MANA,
      category: TokenCategory.METAVERSE,
      name: PolygonMainnetToken.MANA,
    },
    [PolygonMainnetToken.LINK]: {
      allocation: 0.15,
      address: PolygonMainnetTokenAddress.LINK,
      category: TokenCategory.DEFI,
      name: PolygonMainnetToken.LINK,
    },
    [PolygonMainnetToken.SAND]: {
      allocation: 0.15,
      address: PolygonMainnetTokenAddress.SAND,
      category: TokenCategory.NFT,
      name: PolygonMainnetToken.SAND,
    },
    [PolygonMainnetToken.SOL]: {
      allocation: 0.15,
      address: PolygonMainnetTokenAddress.SOL,
      category: TokenCategory['L1/L2'],
      name: PolygonMainnetToken.SOL,
    },
  },
  1: {
    [PolygonMainnetToken.ETH]: {
      allocation: 0.1625,
      address: PolygonMainnetTokenAddress.ETH,
      category: TokenCategory.OG,
      name: PolygonMainnetToken.ETH,
    },
    [PolygonMainnetToken.BTC]: {
      allocation: 0.1625,
      address: PolygonMainnetTokenAddress.BTC,
      category: TokenCategory.OG,
      name: PolygonMainnetToken.BTC,
    },
    [PolygonMainnetToken.MANA]: {
      allocation: 0.16875,
      address: PolygonMainnetTokenAddress.MANA,
      category: TokenCategory.METAVERSE,
      name: PolygonMainnetToken.MANA,
    },
    [PolygonMainnetToken.LINK]: {
      allocation: 0.16875,
      address: PolygonMainnetTokenAddress.LINK,
      category: TokenCategory.DEFI,
      name: PolygonMainnetToken.LINK,
    },
    [PolygonMainnetToken.SAND]: {
      allocation: 0.16875,
      address: PolygonMainnetTokenAddress.SAND,
      category: TokenCategory.NFT,
      name: PolygonMainnetToken.SAND,
    },
    [PolygonMainnetToken.SOL]: {
      allocation: 0.16875,
      address: PolygonMainnetTokenAddress.SOL,
      category: TokenCategory['L1/L2'],
      name: PolygonMainnetToken.SOL,
    },
  },
  2: {
    [PolygonMainnetToken.ETH]: {
      allocation: 0.125,
      address: PolygonMainnetTokenAddress.ETH,
      category: TokenCategory.OG,
      name: PolygonMainnetToken.ETH,
    },
    [PolygonMainnetToken.BTC]: {
      allocation: 0.125,
      address: PolygonMainnetTokenAddress.BTC,
      category: TokenCategory.OG,
      name: PolygonMainnetToken.BTC,
    },
    [PolygonMainnetToken.MANA]: {
      allocation: 0.1875,
      address: PolygonMainnetTokenAddress.MANA,
      category: TokenCategory.METAVERSE,
      name: PolygonMainnetToken.MANA,
    },
    [PolygonMainnetToken.LINK]: {
      allocation: 0.1875,
      address: PolygonMainnetTokenAddress.LINK,
      category: TokenCategory.DEFI,
      name: PolygonMainnetToken.LINK,
    },
    [PolygonMainnetToken.SAND]: {
      allocation: 0.1875,
      address: PolygonMainnetTokenAddress.SAND,
      category: TokenCategory.NFT,
      name: PolygonMainnetToken.SAND,
    },
    [PolygonMainnetToken.SOL]: {
      allocation: 0.1875,
      address: PolygonMainnetTokenAddress.SOL,
      category: TokenCategory['L1/L2'],
      name: PolygonMainnetToken.SOL,
    },
  },
  3: {
    [PolygonMainnetToken.ETH]: {
      allocation: 0.0875,
      address: PolygonMainnetTokenAddress.ETH,
      category: TokenCategory.OG,
      name: PolygonMainnetToken.ETH,
    },
    [PolygonMainnetToken.BTC]: {
      allocation: 0.0875,
      address: PolygonMainnetTokenAddress.BTC,
      category: TokenCategory.OG,
      name: PolygonMainnetToken.BTC,
    },
    [PolygonMainnetToken.MANA]: {
      allocation: 0.20625,
      address: PolygonMainnetTokenAddress.MANA,
      category: TokenCategory.METAVERSE,
      name: PolygonMainnetToken.MANA,
    },
    [PolygonMainnetToken.LINK]: {
      allocation: 0.20625,
      address: PolygonMainnetTokenAddress.LINK,
      category: TokenCategory.DEFI,
      name: PolygonMainnetToken.LINK,
    },
    [PolygonMainnetToken.SAND]: {
      allocation: 0.20625,
      address: PolygonMainnetTokenAddress.SAND,
      category: TokenCategory.NFT,
      name: PolygonMainnetToken.SAND,
    },
    [PolygonMainnetToken.SOL]: {
      allocation: 0.20625,
      address: PolygonMainnetTokenAddress.SOL,
      category: TokenCategory['L1/L2'],
      name: PolygonMainnetToken.SOL,
    },
  },
  4: {
    [PolygonMainnetToken.ETH]: {
      allocation: 0.05,
      address: PolygonMainnetTokenAddress.ETH,
      category: TokenCategory.OG,
      name: PolygonMainnetToken.ETH,
    },
    [PolygonMainnetToken.BTC]: {
      allocation: 0.05,
      address: PolygonMainnetTokenAddress.BTC,
      category: TokenCategory.OG,
      name: PolygonMainnetToken.BTC,
    },
    [PolygonMainnetToken.MANA]: {
      allocation: 0.225,
      address: PolygonMainnetTokenAddress.MANA,
      category: TokenCategory.METAVERSE,
      name: PolygonMainnetToken.MANA,
    },
    [PolygonMainnetToken.LINK]: {
      allocation: 0.225,
      address: PolygonMainnetTokenAddress.LINK,
      category: TokenCategory.DEFI,
      name: PolygonMainnetToken.LINK,
    },
    [PolygonMainnetToken.SAND]: {
      allocation: 0.225,
      address: PolygonMainnetTokenAddress.SAND,
      category: TokenCategory.NFT,
      name: PolygonMainnetToken.SAND,
    },
    [PolygonMainnetToken.SOL]: {
      allocation: 0.225,
      address: PolygonMainnetTokenAddress.SOL,
      category: TokenCategory['L1/L2'],
      name: PolygonMainnetToken.SOL,
    },
  },
});
