/**
 *
 * @returns [string, string, string][] | null
 * @description returns an array of triplets, the first
 * string is the amount in wei, the second is the createTime in unix,
 * the third is the token address
 */

import { useQuery, UseQueryResult } from 'react-query';
import { Contract } from 'web3-eth-contract';

import { ServerStateKey } from 'shared/enums/serverStateKey.enums';
import { getPerformance } from 'shared/contract/contractHelpers';

export const useGetPerformance = (
  contract: Contract | null
): UseQueryResult<[string, string, string][] | null, Error> =>
  useQuery<[string, string, string][] | null, Error>(
    [ServerStateKey.PERFORMANCE_DATA],
    () => getPerformance(contract),
    {
      cacheTime: 0,
      enabled: !!contract,
    }
  );
