import { ReactNode } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

interface WalletCardContainerProps {
  disabled?: boolean;
}

const WalletCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 212px;
  max-width: 212px;
  height: 56px;
  background-color: white;
  border: ${({ disabled }: WalletCardContainerProps) =>
    disabled ? '1px solid #BDBDBD' : '1px solid black'};
  cursor: ${({ disabled }: WalletCardContainerProps) =>
    disabled ? 'not-allowed' : 'pointer'};
  cursor: ${({ disabled }: WalletCardContainerProps) =>
    disabled ? 'not-allowed' : 'pointer'};
  transition: ${({ disabled }: WalletCardContainerProps) =>
    disabled ? 'none' : 'all 0.2s ease-in-out'};

  .wallet-card-avatar {
    height: 40px;
    width: 40px;
    margin-right: 16px;
  }

  p {
    color: ${({ disabled }: WalletCardContainerProps) =>
      disabled ? 'rgba(0, 0, 0, 0.54)' : 'black'};
  }

  &:hover {
    opacity: ${({ disabled }: WalletCardContainerProps) =>
      disabled ? '1' : '0.5'};
  }
`;

interface WalletCardProps {
  src?: string;
  alt?: string;
  disabled?: boolean;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

export const WalletCard = ({
  src,
  alt,
  disabled = false,
  children,
  className = '',
  onClick,
}: WalletCardProps) => {
  return (
    <WalletCardContainer
      className={className}
      disabled={disabled}
      onClick={onClick}
    >
      {src ? (
        <img
          className="wallet-card-avatar"
          src={src}
          alt={alt ? alt : 'missing-alt'}
        />
      ) : (
        <></>
      )}
      <Typography variant="body2" sx={{ fontWeight: 600 }}>
        {children}
      </Typography>
    </WalletCardContainer>
  );
};
