import axios from 'axios';

const MEREO_SERVER_URI = 'https://backend.mereo.app';

export const mereoServerServiceApiRequest = {
  get: async (
    url: string,
    params: { [key: string]: string | number | boolean }
  ) => {
    const response = await mereoServerServiceAPI.get(url, {
      params,
    });

    return response.data;
  },
};

const getMereoServerServiceURI = () => MEREO_SERVER_URI;
const mereoServerServiceAPI = axios.create({
  baseURL: getMereoServerServiceURI(),
});
