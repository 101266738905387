import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Button,
  Grid,
  Card,
  Avatar,
  CardActions,
} from '@mui/material';

import { Layout } from 'shared/components/Layout/Layout.components';
import { GenericAPIWrapper } from 'shared/components/apiWrappers/GenericAPIWrapper.component';
import {
  polygonMainnetMvpBasket,
} from 'shared/baskets/baskets';
import { DashboardChart } from 'shared/components/charts/DashboardChart.components';
import { PortfolioTable } from 'shared/components/PortfolioTable/PortfolioTable.components';
import { useGetPriceOfCoinsVsCurrencies } from 'shared/hooks/apis/services/mereoServer/useGetPriceOfCoinVsCurrencies';
import { useYTDReturn } from 'shared/hooks/useYTDReturn';
import { coinSymbols } from 'shared/tokens/tokens';

import EcashLogo from 'assets/images/logos/ecash-logo.png';
import KucoinLogo from 'assets/images/logos/kucoin-logo.png';
import XensorLogo from 'assets/images/logos/xensor-logo.png';
import { Currency } from '../../shared/enums/currency.enums';

const CustomCard = styled(Card)`
  position: sticky;
  display: flex;
  flex-direction: column;

  .card-header {
    display: flex;
    padding: 16px;

    &-avatar {
    }

    &-title-container {
      width: 100%;
    }

    &-title {
    }

    &-subheading {
    }
  }

  li {
    display: flex;
    padding: 16px 24px;
    border-bottom: 1px solid #e5e7eb;
    justify-content: space-between;

    h4 {
      margin: 0;
      color: #8e9092;
      font-size: 14px;
      font-weight: 700;
      line-height: 28px;
      text-transform: uppercase;

      .green {
        color: #0baa4b;
      }

      .red {
        color: #df4857;
      }

      span {
        color: #1e1e12;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-transform: none;
      }
    }
  }

  li:last-of-type {
    margin-bottom: 32px;
    border-bottom: none;
  }
`;

const props = {
  address: '2',
  name: 'Starter',
  tokens: [
    {
      token: 'Ecash',
      logo: EcashLogo,
    },
    {
      token: 'Kucoin',
      logo: KucoinLogo,
    },
    {
      token: 'Xensor',
      logo: XensorLogo,
    },
  ],
  numberOfTokens: 1,
  percentChange: 5.5,
  riskAdjustable: true,
  ctaUrl: 'to be determined',
};

const TokensContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 18px 12px 72px;

  .token-image-border {
    border: 6px solid white;
    margin-left: -48px;
  }
`;

export const BasketDetails = () => {
  const { id } = useParams();
  const riskScore = '1';
  const {
    data: coinPricesToUsd,
    isLoading: isLoadingCoinPricesToUsd,
    error: coinPricesToUsdError,
  } = useGetPriceOfCoinsVsCurrencies(coinSymbols.join(','), Currency.USD);
  const basket = polygonMainnetMvpBasket[riskScore];
  const { amount: YTDAmount, percent: YTDPercent } = useYTDReturn(coinSymbols);

  const allDataIsLoading = isLoadingCoinPricesToUsd;
  const allErrors = coinPricesToUsdError;
  const allData = !!coinPricesToUsd;

  return (
    <Layout width="1194px">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CustomCard>
            <div className="card-header">
              <TokensContainer>
                {props.tokens.map(({ logo, token }, i) => (
                  <Avatar
                    key={token}
                    className="token-image-border"
                    alt={token}
                    sx={{ height: 68, width: 68, zIndex: 999 - i }}
                    src={logo}
                  />
                ))}
              </TokensContainer>
              <div className="card-header-title-container">
                <Typography variant="h4" className="card-header-title">
                  {props.name}
                </Typography>
                <Typography className="card-header-subheading">
                  Created by Mereo{' '}
                  <span>#{id}</span>
                </Typography>
              </div>
            </div>
            <li>
              Basket Value<span>123</span>
            </li>
            <li>
              Estimated APY<span>123</span>
            </li>
            <li>
              Total Profit & Loss<span>123</span>
            </li>
            <li>
              CAGR 5M<span>123</span>
            </li>
            <li>
              Rewards<span>123</span>
            </li>
            <li>
              Created<span>123</span>
            </li>
            <CardActions>
              <Button fullWidth variant="outlined">
                Invest Now
              </Button>
            </CardActions>
          </CustomCard>
        </Grid>
        <Grid item xs={8}>
          <Card>
            <Typography variant="h5">About this Basket</Typography>
            <Typography variant="caption">
              Earn good yield with a global stablecoins basket. It includes a
              large allocation in USD-pegged assets while still looks for some
              alpha on Jarvis' yield farming non-USD stables.
            </Typography>
          </Card>
          <GenericAPIWrapper isLoading={allDataIsLoading} error={allErrors}>
            {allData ? (
              <div className="dashboard-portfolio-content">
                <Typography
                  className="dashboard-portfolio-header"
                  variant="subtitle1"
                >
                  Basket Performance
                </Typography>
                <div className="dashboard-overall-performance-row">
                  <div className="dashboard-overall-performance-item">
                    <Typography
                      className="dashboard-overall-performance-item-caption"
                      variant="caption"
                    >
                      YTD Return
                    </Typography>
                    <div className="dashboard-overall-performance-item-content">
                      <Typography
                        className="dashboard-overall-performance-item-value"
                        variant="h5"
                      >
                        {YTDAmount}
                      </Typography>
                      <Typography
                        className="dashboard-overall-performance-item-ytd-delta"
                        variant="caption"
                      >
                        {YTDPercent}
                      </Typography>
                    </div>
                  </div>
                  <div className="flex-grow-div" />
                </div>
                <Card>
                  <DashboardChart basket={basket} />
                </Card>
                <Typography
                  className="dashboard-allocation-header"
                  variant="subtitle1"
                >
                  Your Basket Target Allocation
                </Typography>
                <Card>
                  <PortfolioTable
                    riskScore={riskScore}
                    basket={polygonMainnetMvpBasket}
                  />
                </Card>
              </div>
            ) : (
              <></>
            )}
          </GenericAPIWrapper>
        </Grid>
      </Grid>
    </Layout>
  );
};
