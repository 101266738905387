import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

export const PortfolioTableStyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.87)',
    border: 0,
    fontSize: '0.75rem',
    lineHeight: '1.5rem',
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.87)',
    '.portfolio-table-logo-container': {
      display: 'flex',
      alignItems: 'center',
      img: {
        height: 24,
        width: 24,
      },
      span: {
        marginLeft: 8,
      },
    },
    '.portfolio-table-progress-container': {
      display: 'flex',
      alignItems: 'center',
      '&-progress-bar': {
        width: '100%',
        transform: 'rotateY(180deg)',
        '.MuiLinearProgress-root': {
          backgroundColor: 'transparent',
        },
        '.MuiLinearProgress-bar': {
          backgroundColor: '#FED500',
        },
      },
      '&-allocation': {
        marginLeft: 8,
        width: 64,
      },
    },
  },
}));
