/**
 *
 * @returns [string, string][] | null
 * @description returns an array of tuples, the first
 * string is the amount in wei, the second is the token address
 */

import { useQuery, UseQueryResult } from 'react-query';
import { Contract } from 'web3-eth-contract';

import { ServerStateKey } from 'shared/enums/serverStateKey.enums';
import { getBasketBalanceFromContract } from 'shared/contract/contractHelpers';

export const useGetBasketBalanceFromContract = (
  contract: Contract | null
): UseQueryResult<[string, string][] | null, Error> =>
  useQuery<[string, string][] | null, Error>(
    [ServerStateKey.BASKET_BALANCE_FROM_CONTRACT],
    () => getBasketBalanceFromContract(contract),
    {
      cacheTime: 0,
      enabled: !!contract,
    }
  );
