import styled from 'styled-components';

export const TransactionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .transaction-header-container {
    display: flex;
    align-self: start;
  }

  h4 {
    margin-bottom: 32px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .transaction-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 100%;


    &-fund-header {
      margin-bottom: 36px;
    }
    
    &-input {
      width: 302px;
      
      img {
        width: 24px;
        height: 24px;
      }
    }
    
    &-input-adornment {
      display: flex;
      align-items: center;
    }

    &-input-text {
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      margin-left: 8px;
    }

    .MuiOutlinedInput-root {
      border-radius: 0;
      border: 1px solid black;
    }

    .MuiFormHelperText-root {
      margin-left: 0;
      color: rgba(0, 0, 0, 0.54);
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .transaction-button-row {
    display: flex;
    align-self: flex-end;

    button {
      &:not(:first-of-type) {
        margin-left: 8px;
      }
    }
  }

  .transaction-error-text {
    color: #F44336;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 16px;
  }
`;
