import { CoinsVsCurrenciesResponse } from 'shared/interfaces/mereoServer/getPriceOfCoinVsCurrency.interfaces';
import {
  V2CoinMarketChartResponse,
  V2CoinPricesAtTimestampResponse,
} from 'shared/interfaces/mereoServer/getCoinHistoryByDate.interfaces';
import { mereoServerServiceApiRequest } from './apis.mereoServer.base';
import { CoinMarketChartResponse } from '../../interfaces/mereoServer/getCoinMarketChart.interfaces';

export const getPriceOfCoinsVsCurrencies = (
  ids: string,
  vsCurrencies: string
): Promise<CoinsVsCurrenciesResponse> =>
  mereoServerServiceApiRequest.get('v1/coins/simple/price', {
    ids,
    vs_currencies: vsCurrencies,
  });

export const getCoinMarketChart = async (
  id: string,
  vsCurrency: string,
  days: string,
  interval?: string
): Promise<CoinMarketChartResponse> => {
  const response = await getBulkCoinMarketChart(id, vsCurrency, days, interval);
  return response[id];
};

export const getBulkCoinMarketChart = (
  ids: string,
  vsCurrency: string,
  days: string,
  interval?: string
): Promise<V2CoinMarketChartResponse> =>
  mereoServerServiceApiRequest.get(`v2/coins/market_chart`, {
    ids,
    vs_currency: vsCurrency,
    days,
    ...(interval ? { interval } : {}),
  });

export const getBulkCoinHistoryByDate = (
  ids: string,
  date: string
): Promise<V2CoinPricesAtTimestampResponse> =>
  mereoServerServiceApiRequest.get(`v2/coins/history`, {
    ids,
    date,
  });
