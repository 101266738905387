import {
  PolygonMvpBasket,
  PolygonMainnetMvpBasketData,
} from 'shared/baskets/baskets';
import { PolygonMainnetToken } from 'shared/enums/token.enums';
import { LatestNormalizedPerformanceData } from 'pages/dashboard/dashboardHelpers';

export interface NormalizeBasketReturn extends PolygonMainnetMvpBasketData {
  amount?: string;
}

export const normalizeBasket = (
  riskScore: string,
  basket: PolygonMvpBasket,
  performanceValues?: LatestNormalizedPerformanceData
): NormalizeBasketReturn[] => {
  const pickedBasket = basket[riskScore] || {};
  return (Object.keys(pickedBasket) as Array<keyof typeof PolygonMainnetToken>)
    .map((token) => pickedBasket[token])
    .map((normalizeBasketValue) => ({
      ...normalizeBasketValue,
      ...(performanceValues
        ? { amount: performanceValues[normalizeBasketValue.address] }
        : {}),
    }));
};
