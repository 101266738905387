import { useQuery, UseQueryResult } from 'react-query';
import { ServerStateKey } from 'shared/enums/serverStateKey.enums';
import { V2CoinPricesAtTimestampResponse } from 'shared/interfaces/mereoServer/getCoinHistoryByDate.interfaces';
import { getBulkCoinHistoryByDate } from 'shared/services/mereoServer/apis.mereoServer.queries';
import { AxiosError } from 'axios';

interface GetBulkCoinHistoryByDateConfig {
  ids: string;
  date: string;
}

export const useGetBulkCoinHistoryByDate = ({
  ids,
  date,
}: GetBulkCoinHistoryByDateConfig): UseQueryResult<
  V2CoinPricesAtTimestampResponse,
  AxiosError<any>
> => {
  return useQuery<V2CoinPricesAtTimestampResponse, AxiosError>(
    [ServerStateKey.COIN_MARKET_CHART, ids, date],
    () => getBulkCoinHistoryByDate(ids, date),
    {
      enabled: !!ids && !!date,
    }
  );
};
