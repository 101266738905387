/**
 *
 * @returns string | null
 * @description returns the balance of the connected
 * account in ETH
 */

import { useQuery, UseQueryResult } from 'react-query';
import Web3 from 'web3';

import { ServerStateKey } from 'shared/enums/serverStateKey.enums';
import { getEthBalanceFromAccount } from 'shared/contract/contractHelpers';

export const useGetEthBalanceFromAccount = (
  provider: Web3 | null,
  accountAddress: string | null
): UseQueryResult<string | null, Error> =>
  useQuery<string | null, Error>(
    [ServerStateKey.ETH_BALANCE_FROM_ACCOUNT, accountAddress],
    () => getEthBalanceFromAccount(provider, accountAddress),
    {
      cacheTime: 0,
      enabled: !!provider && !!accountAddress,
    }
  );
