export enum EthereumMainnetToken {
  ETH = 'ETH',
  AXS = 'AXS',
  BTC = 'BTC',
  LUNA = 'LUNA',
  SAND = 'SAND',
  SOL = 'SOL',
}

export enum PolygonMainnetToken {
  ETH = 'ETH',
  LINK = 'LINK',
  BTC = 'BTC',
  MANA = 'MANA',
  SAND = 'SAND',
  SOL = 'SOL',
}