import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import { CoinsVsCurrenciesResponse } from 'shared/interfaces/mereoServer/getPriceOfCoinVsCurrency.interfaces';
import { ServerStateKey } from 'shared/enums/serverStateKey.enums';
import { getPriceOfCoinsVsCurrencies } from 'shared/services/mereoServer/apis.mereoServer.queries';

export const useGetPriceOfCoinsVsCurrencies = (
  ids: string,
  vsCurrencies: string
): UseQueryResult<CoinsVsCurrenciesResponse, AxiosError<any>> => {
  return useQuery<CoinsVsCurrenciesResponse, AxiosError>(
    [ServerStateKey.COIN_VS_CURRENCIES_PRICE, ids, vsCurrencies],
    () => getPriceOfCoinsVsCurrencies(ids, vsCurrencies),
    {
      enabled: !!ids && !!vsCurrencies,
    }
  );
};
