/**
 *
 * @returns string | null
 * @description returns the risk score of an account
 */

import { useQuery, UseQueryResult } from 'react-query';
import { Contract } from 'web3-eth-contract';

import { ServerStateKey } from 'shared/enums/serverStateKey.enums';
import { getRiskScore } from 'shared/contract/contractHelpers';


export const useGetRiskScore = (
  contract: Contract | null,
  accountAddress: string | null
): UseQueryResult<string | null, Error> =>
  useQuery<string | null, Error>(
    [ServerStateKey.RISK_SCORE, accountAddress],
    () => getRiskScore(contract, accountAddress),
    {
      enabled: !!contract && !!accountAddress,
    }
  );
