import { format, isAfter, isBefore, startOfYear } from 'date-fns';
import { getNormalizedPerformanceData } from 'pages/dashboard/dashboardHelpers';
import { useMetaMaskContext } from 'shared/contexts/metamask.contexts';
import Web3 from 'web3';
import { addressToCryptoWatchId, WBTCAddress } from 'shared/tokens/tokens';
import { useGetBulkCoinHistoryByDate } from './apis/services/mereoServer/useGetBulkCoinHistoryByDate';
import { useGetPerformance } from './contracts/queries/useGetPerformance';
import { useTotalBasketAmountInUsd } from './useTotalBasketAmountInUsd';
import { Currency } from '../enums/currency.enums';

export interface YTDReturnResponse {
  amount: string;
  percent: string;
}

export const useYTDReturn = (coinSymbols: string[]): YTDReturnResponse => {
  const { socketContract } = useMetaMaskContext();
  const currentAmount = useTotalBasketAmountInUsd();
  const { data: rawPerformanceData = null } = useGetPerformance(socketContract);
  const current = new Date();
  const beginningOfYear = startOfYear(current);
  const timestampMap = getNormalizedPerformanceData(rawPerformanceData);
  const descTimestamps = Object.keys(timestampMap).sort().reverse();
  const firstTimestampBeforeStartOfYear = descTimestamps.find((timestamp) => {
    return isBefore(new Date(parseInt(timestamp)), beginningOfYear);
  });
  const firstTimestampAfterStartOfYear = descTimestamps.find((timestamp) =>
    isAfter(new Date(parseInt(timestamp)), beginningOfYear)
  );
  const beginningTimestamp =
    firstTimestampBeforeStartOfYear ||
    firstTimestampAfterStartOfYear ||
    String(new Date().getTime());
  const dateOfPriceHistory = isBefore(
    new Date(parseInt(beginningTimestamp)),
    beginningOfYear
  )
    ? beginningOfYear
    : new Date(parseInt(beginningTimestamp));
  const useGetBulkCoinHistoryByDateConfig = {
    ids: coinSymbols.join(','),
    date: format(dateOfPriceHistory, 'yyyy-MM-dd'),
  };
  const { data: bulkCoinHistoryByDateData } = useGetBulkCoinHistoryByDate(
    useGetBulkCoinHistoryByDateConfig
  );
  if (!bulkCoinHistoryByDateData)
    return {
      amount: '0',
      percent: '0',
    };

  const coinHistoryPrices = Object.keys(bulkCoinHistoryByDateData).reduce(
    (acc: { [key: string]: number }, curr: string, index: number) => {
      return {
        ...acc,
        [curr]: bulkCoinHistoryByDateData[curr][Currency.USD],
      };
    },
    {}
  );
  const beginningPerformance = timestampMap[beginningTimestamp];
  const beginningAmount = (beginningPerformance || []).reduce((acc, curr) => {
    const id = addressToCryptoWatchId[curr.tokenAddress];
    const usdPrice = coinHistoryPrices ? coinHistoryPrices[id] : 0;
    const isWBTCAddress = curr.tokenAddress === WBTCAddress;
    const decimalFactor = isWBTCAddress ? Math.pow(10, -8) : Math.pow(10, -18);
    const subtotal = +Web3.utils.toBN(curr.amount) * decimalFactor * usdPrice;
    return acc + subtotal;
  }, 0);
  const amount = currentAmount - beginningAmount;
  const percent = (amount / beginningAmount) * 100;
  const sign = percent >= 0 ? '+' : '';
  return {
    amount: amount.toFixed(2),
    percent: `${sign}${percent.toFixed(2)}%`,
  };
};
