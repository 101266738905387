import { format, sub, isBefore } from 'date-fns';
import Web3 from 'web3';

import { polygonMainnetMvpBasket } from 'shared/baskets/baskets';
import { PolygonMainnetToken } from 'shared/enums/token.enums';
import { CoinMarketChartPrice } from 'shared/interfaces/coinGecko/getCoinMarketChart.interfaces';
import { INTERVAL } from 'shared/components/charts/DashboardChart.components';
import {
  addressToCoinGeckoId,
  addressToCryptoWatchId,
  WBTCAddress,
} from '../../shared/tokens/tokens';
import { Currency } from '../../shared/enums/currency.enums';

export type RawPerformanceData = [string, string, string][];

interface NormalizedPerformanceData {
  [key: string]: {
    amount: string;
    tokenAddress: string;
  }[];
}

interface CoinIdToUsdPriceHistories {
  [key: string]: CoinMarketChartPrice[];
}

interface Holdings {
  [key: string]: number;
}

export interface LatestNormalizedPerformanceData {
  [key: string]: string;
}

export const getLatestNormalizedPerformanceData = (
  data: RawPerformanceData | null,
  riskScore: string | null
): LatestNormalizedPerformanceData => {
  if (!Array.isArray(data) || !riskScore) {
    return {};
  }

  const pickedBasket =
    polygonMainnetMvpBasket[(parseInt(riskScore) + 1).toString()];
  const initializedPerformance = (
    Object.keys(pickedBasket) as PolygonMainnetToken[]
  ).reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [pickedBasket[currentValue].address]: '0',
    }),
    {} as { [key: string]: string }
  );

  const timestampMap = getNormalizedPerformanceData(data);

  const sortedTimestamp = Object.keys(timestampMap).sort();
  const latestPerformance =
    timestampMap[sortedTimestamp[sortedTimestamp.length - 1]];

  return (latestPerformance || []).reduce(
    (accumulator, { amount, tokenAddress }) => ({
      ...accumulator,
      [tokenAddress]: amount,
    }),
    initializedPerformance
  );
};

// if timestamp before YTD DATE exists, use the most recent one before YTD DATE,
// if no timestamp fits the above criteria^ use the oldest one available (since these dates will be after YTD DATE)

export const getYTDPositionData = (
  data: RawPerformanceData | null,
  riskScore: string | null
): LatestNormalizedPerformanceData => {
  if (!Array.isArray(data) || !riskScore) {
    return {};
  }

  const pickedBasket =
    polygonMainnetMvpBasket[(parseInt(riskScore) + 1).toString()];
  const initializedPerformance = (
    Object.keys(pickedBasket) as PolygonMainnetToken[]
  ).reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [pickedBasket[currentValue].address]: '0',
    }),
    {} as { [key: string]: string }
  );

  const timestampMap = getNormalizedPerformanceData(data);

  const sortedTimestamp = Object.keys(timestampMap).sort();

  const latestPerformance =
    timestampMap[sortedTimestamp[sortedTimestamp.length - 1]];

  return (latestPerformance || []).reduce(
    (accumulator, { amount, tokenAddress }) => ({
      ...accumulator,
      [tokenAddress]: amount,
    }),
    initializedPerformance
  );
};

export const fullPerformanceData = (data: RawPerformanceData): any => {
  if (!Array.isArray(data)) {
    return [];
  }

  const timestampMap = data.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue[2]]: [
        ...(accumulator[currentValue[2]] || []),
        {
          amount: currentValue[1],
          tokenAddress: currentValue[0],
        },
      ],
    }),
    {} as NormalizedPerformanceData
  );

  return timestampMap;
};

export const hydrateChartData = (
  performanceData: any,
  coinIdToUsdPriceHistories: CoinIdToUsdPriceHistories | undefined,
  quantity: number,
  interval: INTERVAL,
  realtimeValue?: number
) => {
  if (!performanceData || !coinIdToUsdPriceHistories) {
    return [];
  }
  const startingFromDay = new Date();
  const dateArr = [];

  const performanceDataHoldings = getTimestampIndexedHoldings(performanceData);
  const subtractionMap = {
    [INTERVAL.HOURLY]: { hours: quantity },
    [INTERVAL.DAILY]: { days: quantity },
    [INTERVAL.WEEKLY]: { weeks: quantity },
  };

  const leftDate = sub(startingFromDay, subtractionMap[interval]);
  const descTimestamps = Object.keys(performanceDataHoldings).sort().reverse();
  const preexistingTimestamp = descTimestamps.find((timestamp) => {
    const timestampInMilliseconds = parseInt(timestamp) * 1000;
    return isBefore(new Date(timestampInMilliseconds), leftDate);
  });

  let leftHoldings = preexistingTimestamp
    ? performanceDataHoldings[preexistingTimestamp]
    : Object.keys(coinIdToUsdPriceHistories).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: 0,
        }),
        {}
      );
  let index =
    descTimestamps.indexOf(preexistingTimestamp || '') === -1
      ? descTimestamps.length - 1
      : descTimestamps.indexOf(preexistingTimestamp || '');

  for (let i = quantity; i >= 0; i--) {
    const currDate = sub(startingFromDay, {
      ...(interval === 'hourly' && { hours: i }),
      ...(interval === 'daily' && { days: i }),
      ...(interval === 'weekly' && { weeks: i }),
    });
    const portfolioChange = new Date(parseInt(descTimestamps[index]) * 1000);
    if (isBefore(portfolioChange, currDate)) {
      leftHoldings = performanceDataHoldings[descTimestamps[index]];
      index = index - 1;
    }

    const newObj = { ...leftHoldings };
    const amountInUsd = Object.keys(leftHoldings).reduce((acc, coinId) => {
      if (realtimeValue !== undefined && i === 0) {
        return realtimeValue;
      }
      const isWBTC = coinId === 'btc';
      const decimalFactor = isWBTC ? Math.pow(10, -8) : Math.pow(10, -18);
      const subtotal =
        newObj[coinId] *
        decimalFactor *
        coinIdToUsdPriceHistories[coinId][quantity - i][1];
      return acc + subtotal;
    }, 0);

    dateArr.push({
      date: format(currDate, 'yyyy-MM-dd'),
      amount: amountInUsd.toFixed(2),
    });
  }

  return dateArr;
};

export const totalBasketAmountInUsd = (
  currentBasketData: [string, string][] | null | undefined,
  coinPricesToUsd: { [key: string]: { [key: string]: number } }
) =>
  (currentBasketData || []).reduce(
    (accumulator: number, currentValue: [string, string]) => {
      const address = currentValue[0];
      if (address === '0x0000000000000000000000000000000000000000')
        return accumulator;

      const coinGeckoId = addressToCoinGeckoId[address];
      const usdRate = coinPricesToUsd[coinGeckoId][Currency.USD];
      const isWBTCAddress = currentValue[0] === WBTCAddress;
      const decimalFactor = isWBTCAddress
        ? Math.pow(10, -8)
        : Math.pow(10, -18);
      return (
        accumulator +
        +Web3.utils.toBN(currentValue[1]) * decimalFactor * usdRate
      );
    },
    0
  );

export const totalBasketAmountInEth = (
  currentBasketData: [string, string][] | null | undefined,
  coinPricesToUsd: { [key: string]: { [key: string]: number } }
) => {
  const totalUsd = totalBasketAmountInUsd(currentBasketData, coinPricesToUsd);
  const usdRate = coinPricesToUsd['matic-network'][Currency.USD];
  return totalUsd / usdRate;
};

export const getNormalizedPerformanceData = (
  data: RawPerformanceData | null
): NormalizedPerformanceData => {
  if (!Array.isArray(data)) {
    return {};
  }

  const timestampMap = data.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue[2]]: [
        ...(accumulator[currentValue[2]] || []),
        {
          amount: currentValue[1],
          tokenAddress: currentValue[0],
        },
      ],
    }),
    {} as NormalizedPerformanceData
  );

  const timestampMapInMilliseconds = Object.keys(timestampMap).reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [String(+currentValue * 1000)]: timestampMap[currentValue],
    }),
    {} as NormalizedPerformanceData
  );

  return timestampMapInMilliseconds;
};

const getTimestampIndexedHoldings = (
  performanceData: any
): { [key: string]: Holdings } => {
  return Object.keys(performanceData).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: (performanceData[curr] || []).reduce((acc2: any, curr2: any) => {
        return {
          ...acc2,
          [addressToCryptoWatchId[curr2.tokenAddress]]: curr2.amount,
        };
      }, {}),
    }),
    {}
  );
};
