import { useState } from 'react';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useRiskScoreContext } from 'shared/contexts/riskScore.contexts';

const RiskScoreSliderContainer = styled(Slider)(({ theme }) => ({
  color: 'black',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 44,
    width: 44,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(0, 0, 0, 0.16)',
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
}));

// TODO: find SliderThumb props
const RiskScoreSliderThumbComponent = (props: any) => {
  const { children, ...other } = props;
  const { riskScore } = useRiskScoreContext();
  return (
    <SliderThumb {...other}>
      {children}
      <Typography variant="subtitle1">{riskScore}</Typography>
    </SliderThumb>
  );
};

interface RiskScoreSliderProps {
  className: string;
}

export const RiskScoreSlider = ({ className = '' }: RiskScoreSliderProps) => {
  const { riskScore, setRiskScore } = useRiskScoreContext();
  const [defaultRiskScore] = useState<number>(riskScore);
  // TODO: find correct html input event type
  const handleRiskSliderValue = (event: any) => {
    setRiskScore(event.target.value);
  };

  return (
    <RiskScoreSliderContainer
      className={className}
      components={{ Thumb: RiskScoreSliderThumbComponent }}
      min={1}
      max={5}
      defaultValue={defaultRiskScore}
      aria-label="Default"
      valueLabelDisplay="auto"
      onChange={handleRiskSliderValue}
    />
  );
};
