import { useState } from 'react';

export const useMuiMenu = () => {
  const [muiMenuAnchorEl, setMuiMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const muiMenuOpen = Boolean(muiMenuAnchorEl);
  const handleMuiMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMuiMenuAnchorEl(event.currentTarget);
  };
  const handleMuiMenuClose = () => {
    setMuiMenuAnchorEl(null);
  };

  return {
    muiMenuAnchorEl,
    muiMenuOpen,
    handleMuiMenuClick,
    handleMuiMenuClose,
  };
};
