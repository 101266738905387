import { createContext, useContext, useState, ReactNode } from 'react';

interface RiskScoreContextInterface {
  riskScore: number;
  setRiskScore: (score: number) => void;
}

const initializeRiskScoreContextValues = () => ({
  riskScore: 3,
  setRiskScore: () => null,
});

const { riskScore, setRiskScore } = initializeRiskScoreContextValues();

const RiskScoreContext = createContext<RiskScoreContextInterface>({
  riskScore,
  setRiskScore,
});

interface RiskScoreProviderProps {
  children: ReactNode;
}

export const RiskScoreContextProvider = ({
  children,
}: RiskScoreProviderProps) => {
  const [score, setScore] = useState<number>(riskScore);

  const handleSetRiskScore = (score: number) => setScore(score);

  return (
    <RiskScoreContext.Provider
      value={{ riskScore: score, setRiskScore: handleSetRiskScore }}
    >
      {children}
    </RiskScoreContext.Provider>
  );
};

export const useRiskScoreContext = () => useContext(RiskScoreContext);
