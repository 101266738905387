import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';

import { App } from 'App';
import { Congratulations } from 'pages/createAccounts/Congratulations/Congratulations.pages';
import { Connect } from 'pages/createAccounts/Connect/Connect.pages';
import { Deposit } from 'pages/createAccounts/Deposit/Deposit.pages';
import { RiskAssessment } from 'pages/createAccounts/RiskAssessment/RiskAssessment.pages';
import { CreateAccount } from 'pages/createAccounts/CreateAccounts.pages';
import { Dashboard } from 'pages/dashboard/Dashboard.pages';
import { Portfolio } from 'pages/dashboard/Portfolio/Portfolio.pages';
import { MetaMaskContextProvider } from 'shared/contexts/metamask.contexts';
import { MainBackgroundContextProvider } from 'shared/contexts/mainBackground.contexts';
import { RiskScoreContextProvider } from 'shared/contexts/riskScore.contexts';
import { AuthenticatedRoute } from 'shared/components/AuthenticatedRoute/AuthenticatedRoute.components';
import { SnackbarContextProvider } from 'shared/contexts/snackbar.contexts';
import { Web3SocketContextProvider } from './shared/contexts/web3Socket.contexts';
import { Baskets } from './pages/baskets/Baskets.pages';
import { BasketDetails } from './pages/baskets/BasketDetails.pages';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    body1: {
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
      color: 'rgba(0, 0, 0, 0.87)',
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.25rem',
      color: 'rgba(0, 0, 0, 0.87)',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: '600',
      lineHeight: '1.75rem',
      color: 'rgba(0, 0, 0, 0.87)',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: '1.375rem',
      color: 'rgba(0, 0, 0, 0.87)',
      letterSpacing: '0.1px',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: '1.25rem',
      color: 'rgba(0, 0, 0, 0.87)',
      letterSpacing: '0.4px',
    },
    h3: {
      fontSize: '3rem',
      fontWeight: '400',
      lineHeight: '3.5rem',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    h4: {
      fontSize: '2.125rem',
      fontWeight: '400',
      lineHeight: '2.625rem',
      color: 'rgba(0, 0, 0, 0.87)',
      letterSpacing: '0.25px',
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: '500',
      lineHeight: '2rem',
      color: 'rgba(0, 0, 0, 0.87)',
      letterSpacing: '0.15px',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            textTransform: 'none',
            border: '1px solid black',
            color: 'black',
            fontSize: '14px',
            fontWeight: 600,
            borderRadius: 0,
            backgroundColor: 'white',
            transition: 'all 0.2s ease-in-out',
            ':hover': {
              border: '1px solid black',
              opacity: 0.5,
              backgroundColor: 'white',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            textTransform: 'none',
            color: 'black',
            fontSize: '14px',
            fontWeight: 600,
            borderRadius: 0,
            backgroundColor: 'white',
            transition: 'all 0.2s ease-in-out',
            ':hover': {
              opacity: 0.5,
              backgroundColor: 'white',
            },
          },
        },
      ],
    },
  },
});

export const AppWrapper = () => (
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <MainBackgroundContextProvider>
          <Web3SocketContextProvider>
            <MetaMaskContextProvider>
              <RiskScoreContextProvider>
                <SnackbarContextProvider>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <AuthenticatedRoute>
                          <App />
                        </AuthenticatedRoute>
                      }
                    >
                      <Route
                        path="create"
                        element={
                          <AuthenticatedRoute>
                            <CreateAccount />
                          </AuthenticatedRoute>
                        }
                      >
                        <Route
                          path="risk-assessment"
                          element={
                            <AuthenticatedRoute>
                              <RiskAssessment />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="connect"
                          element={
                            <AuthenticatedRoute>
                              <Connect />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="deposit"
                          element={
                            <AuthenticatedRoute>
                              <Deposit />
                            </AuthenticatedRoute>
                          }
                        />
                      </Route>
                      <Route
                        path="congratulations"
                        element={
                          <AuthenticatedRoute>
                            <Congratulations />
                          </AuthenticatedRoute>
                        }
                      />
                      <Route
                        path="dashboard"
                        element={
                          <AuthenticatedRoute>
                            <Dashboard />
                          </AuthenticatedRoute>
                        }
                      >
                        <Route
                          path="portfolio"
                          element={
                            <AuthenticatedRoute>
                              <Portfolio />
                            </AuthenticatedRoute>
                          }
                        />
                      </Route>
                      <Route
                        path="/"
                        element={<Navigate to="/create/risk-assessment" />}
                      />
                      <Route path="baskets" element={<Baskets />} />
                      <Route path="baskets/:id" element={<BasketDetails />} />
                      <Route
                        path="*"
                        element={
                          <main>
                            <p>There's nothing here!</p>
                          </main>
                        }
                      />
                    </Route>
                  </Routes>
                </SnackbarContextProvider>
              </RiskScoreContextProvider>
            </MetaMaskContextProvider>
          </Web3SocketContextProvider>
        </MainBackgroundContextProvider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>
  </ThemeProvider>
);
