import { createContext, useContext, useState, ReactNode } from 'react';

interface MainBackgroundContextProps {
  backgroundColor?: string;
  backgroundImage?: string;
  setBackgroundColor: (color: string) => void;
  setBackgroundImage: (image: string) => void;
}

const initializeMainBackgroundContextValues = () => ({
  backgroundColor: 'transparent',
  backgroundImage: 'none',
  setBackgroundColor: () => null,
  setBackgroundImage: () => null,
});

const {
  backgroundColor,
  backgroundImage,
  setBackgroundColor,
  setBackgroundImage,
} = initializeMainBackgroundContextValues();

const MainBackgroundContext = createContext<MainBackgroundContextProps>({
  backgroundColor,
  backgroundImage,
  setBackgroundColor,
  setBackgroundImage,
});

interface MainBackgroundProviderProps {
  children: ReactNode;
}

export const MainBackgroundContextProvider = ({
  children,
}: MainBackgroundProviderProps) => {
  const [color, setColor] = useState<string>(backgroundColor);
  const [image, setImage] = useState<string>(backgroundImage);

  const handleSetBackgroundColor = (color: string) => setColor(color);
  const handleSetBackgroundImage = (image: string) => setImage(image);

  return (
    <MainBackgroundContext.Provider
      value={{
        backgroundColor: color,
        backgroundImage: image,
        setBackgroundColor: handleSetBackgroundColor,
        setBackgroundImage: handleSetBackgroundImage,
      }}
    >
      {children}
    </MainBackgroundContext.Provider>
  );
};

export const useMainBackgroundContext = () => useContext(MainBackgroundContext);
