import styled from 'styled-components';

export const DepositContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  h4 {
    margin-bottom: 32px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .deposit-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 100%;
    padding: 40px 64px 78px;

    &-redirect-span {
      cursor: pointer;
      text-decoration: underline;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 0.5;
      }
    }

    &-header-container-success,
    &-header-container-cancel {
      display: flex;
      align-items: center;
    }

    &-header-container-success {
      flex-direction: column;
    }

    &-header-container-success-wrapper {
      display: flex;
    }

    &-header-container-success-message {
      margin-bottom: 36px;
    }

    &-icon-header {
      height: 20px:
      width: 20px;
      color: #4CAF50;
      margin-right: 10px;
    }

    &-icon-header-cancel {
      height: 20px:
      width: 20px;
      color: red;
      margin-right: 10px;
    }

    &-header-link {
      cursor: pointer;
      text-decoration: underline;
      margin-left: 6px;
      color: black;
      transition: all 0.2s ease-in-out;

      &:hover {
        opacity: 0.5;
      }
    }

    &-divider {
      width: 100%;
      margin-bottom: 46px;
    }

    &-fund-header {
      margin-bottom: 36px;
    }
    
    &-input {
      width: 302px;
      
      img {
        width: 24px;
        height: 24px;
      }
    }
    
    &-input-adornment {
      display: flex;
      align-items: center;
    }

    &-input-text {
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      margin-left: 8px;
    }

    .MuiOutlinedInput-root {
      border-radius: 0;
      border: 1px solid black;
    }

    .MuiFormHelperText-root {
      margin-left: 0;
      color: rgba(0, 0, 0, 0.54);
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .deposit-next-button {
    margin-top: 54px;

    &-icon {
      margin-left: 8px;
    }

    .MuiCircularProgress-root {
      margin-left: 12px;
    }
  }

  .deposit-error-text {
    color: #F44336;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 16px;
  }
`;
