/**
 *
 * @returns string | null
 * @description returns the basket address of an account
 */

 import { useQuery, UseQueryResult } from 'react-query';
 import { Contract } from 'web3-eth-contract';
 
 import { ServerStateKey } from 'shared/enums/serverStateKey.enums';
 import { getUserBasketAddress } from 'shared/contract/contractHelpers';
 
 
 export const useGetUserBasketAddress = (
   contract: Contract | null,
   accountAddress: string | null
 ): UseQueryResult<string | null, Error> =>
   useQuery<string | null, Error>(
     [ServerStateKey.USER_BASKET_ADDRESS, accountAddress],
     () => getUserBasketAddress(contract, accountAddress),
     {
       enabled: !!contract && !!accountAddress,
     }
   );
 