import styled from 'styled-components';

export const AuthenticatedTopNavContainer = styled.div`
  display: flex;

  .authenticated-top-nav-address-box {
    display: flex;
    align-items: center;
    height: 36px;
    border: 1px solid black;
    padding: 6px;
  }

  .authenticated-top-nav-ethereum-logo,
  .authenticated-top-nav-egg-basket-logo {
    height: 24px;
    width: 24px;
  }

  .authenticated-top-nav-address,
  .authenticated-top-nav-balance {
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
  }

  .authenticated-top-nav-balance,
  .authenticated-top-nav-ethereum-logo {
    margin-right: 6px;
  }

  .authenticated-top-nav-address,
  .authenticated-top-nav-egg-basket-logo {
    margin-left: 6px;
  }

  .nav-more-button {
    height: 36px;
    width: 36px;
    margin-left: 8px;
    min-width: auto;

    svg {
      height: 16px;
    }
  }
`;