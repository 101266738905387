import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Web3 from 'web3';

import { useGetEthBalanceFromAccount } from 'shared/hooks/contracts/queries/useGetEthBalanceFromAccount';
import { AuthenticatedTopNavContainer } from 'shared/components/TopNav/AuthenticatedTopNavContainer';
import { GenericAPIWrapper } from 'shared/components/apiWrappers/GenericAPIWrapper.component';

import MEREO_MARK_LOGO from 'assets/images/logos/mereo-mark-logo.png';
import POLYGON_MATIC_LOGO from 'assets/images/logos/polygon-matic-logo.png';

const formatAddressForTopNav = (address: string | null) => {
  if (address === null || address.length < 0) {
    return '';
  }
  return `${address.substring(0, 6)}...${address.substring(
    address.length - 4,
    address.length
  )}`;
};

interface AuthenticatedNavProps {
  accountAddress: string;
  provider: Web3;
}

export const AuthenticatedTopNav = ({
  accountAddress,
  provider,
}: AuthenticatedNavProps) => {
  const {
    data: ethBalanceFromAccount,
    isLoading: ethBalanceFromAccountIsLoading,
    error: ethBalanceFromAccountError,
  } = useGetEthBalanceFromAccount(provider, accountAddress);

  return (
    <AuthenticatedTopNavContainer>
      <GenericAPIWrapper
        isLoading={ethBalanceFromAccountIsLoading}
        error={ethBalanceFromAccountError}
      >
        {!!ethBalanceFromAccount ? (
          <>
            <div className="authenticated-top-nav-address-box">
              <img
                className="authenticated-top-nav-ethereum-logo"
                src={POLYGON_MATIC_LOGO}
                alt={'Polygon Matic logo'}
              />
              <Typography className="authenticated-top-nav-balance">
                {ethBalanceFromAccount !== null ? ethBalanceFromAccount : 0} MATIC
              </Typography>
              <Divider orientation="vertical" light />
              <Typography className="authenticated-top-nav-address">
                {formatAddressForTopNav(accountAddress)}
              </Typography>
              <img
                className="authenticated-top-nav-egg-basket-logo"
                src={MEREO_MARK_LOGO}
                alt={'Mereo mark logo'}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </GenericAPIWrapper>
    </AuthenticatedTopNavContainer>
  );
};
