import { Outlet } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';

import { TopNav } from 'shared/components/TopNav/TopNav.components';
import { useSnackbarContext } from 'shared/contexts/snackbar.contexts';

export const App = () => {
  const { horizontal, vertical, open, message, handleClose } =
    useSnackbarContext();
  return (
    <>
      <TopNav />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
      />
      <Outlet />
    </>
  );
};
