import Web3 from 'web3';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { PortfolioTableStyledTableCell } from 'shared/components/PortfolioTable/PortfolioTableStyledTableCell.components';
import { PortfolioTableStyledTableRow } from 'shared/components/PortfolioTable/PortfolioTableStyledTableRow.components';

import { PolygonMvpBasket } from 'shared/baskets/baskets';
import { PolygonMainnetToken } from 'shared/enums/token.enums';
import {
  normalizeBasket,
  NormalizeBasketReturn,
} from 'shared/components/PortfolioTable/portfolioTableHelpers';
import { LatestNormalizedPerformanceData } from 'pages/dashboard/dashboardHelpers';

import ETH_LOGO from 'assets/images/logos/ethereum-eth-logo.png';
import BTC_LOGO from 'assets/images/logos/bitcoin-btc-logo.png';
import MANA_LOGO from 'assets/images/logos/mana-logo.png';
import LINK_LOGO from 'assets/images/logos/link-logo.png';
import SAND_LOGO from 'assets/images/logos/the-sandbox-sand-logo.png';
import SOL_LOGO from 'assets/images/logos/solana-sol-logo.png';

const logosMap = {
  [PolygonMainnetToken.ETH]: ETH_LOGO,
  [PolygonMainnetToken.BTC]: BTC_LOGO,
  [PolygonMainnetToken.MANA]: MANA_LOGO,
  [PolygonMainnetToken.LINK]: LINK_LOGO,
  [PolygonMainnetToken.SAND]: SAND_LOGO,
  [PolygonMainnetToken.SOL]: SOL_LOGO,
};

interface PortfolioTableProps {
  riskScore: string | null;
  basket: PolygonMvpBasket | null | undefined;
  performanceValues?: LatestNormalizedPerformanceData;
  priceOfEthInUsd?: number;
}

interface PortfolioTableBodyProps {
  data: NormalizeBasketReturn[];
  priceOfEthInUsd?: number;
}

const PortfolioTableBody = ({
  data = [],
  priceOfEthInUsd = 0,
}: PortfolioTableBodyProps) => {
  return (
    <TableBody>
      {data.map(
        ({
          allocation,
          address,
          category,
          name,
          amount,
        }: NormalizeBasketReturn) => (
          <PortfolioTableStyledTableRow key={address}>
            <PortfolioTableStyledTableCell style={{ width: '20%' }} scope="row">
              <div className="portfolio-table-logo-container">
                <img src={logosMap[name]} alt={name} />
                <span>{name}</span>
              </div>
            </PortfolioTableStyledTableCell>
            <PortfolioTableStyledTableCell style={{ width: '15%' }}>
              {category}
            </PortfolioTableStyledTableCell>
            {amount && priceOfEthInUsd ? (
              <PortfolioTableStyledTableCell style={{ width: '15%' }}>
                $
                {(priceOfEthInUsd * (+Web3.utils.fromWei(amount) || 0)).toFixed(
                  2
                )}
              </PortfolioTableStyledTableCell>
            ) : (
              <></>
            )}
            <PortfolioTableStyledTableCell
              style={{ width: '65%' }}
              align="right"
            >
              <div className="portfolio-table-progress-container">
                <div className="portfolio-table-progress-container-progress-bar">
                  <LinearProgress
                    variant="determinate"
                    value={allocation * 100}
                  />
                </div>
                <span className="portfolio-table-progress-container-allocation">
                  {allocation * 100}&#37;
                </span>
              </div>
            </PortfolioTableStyledTableCell>
          </PortfolioTableStyledTableRow>
        )
      )}
    </TableBody>
  );
};

export const PortfolioTable = ({
  riskScore,
  basket,
  performanceValues = {},
  priceOfEthInUsd = 0,
}: PortfolioTableProps) => {
  if (!riskScore || !basket) {
    return <div>risk score or basket empty</div>;
  }

  const hasPerformanceValues = Object.keys(performanceValues).length > 0;

  return (
    <TableContainer>
      <Table aria-label="Portfolio table">
        <TableHead>
          <TableRow>
            <PortfolioTableStyledTableCell style={{ width: '20%' }}>
              Symbol
            </PortfolioTableStyledTableCell>
            <PortfolioTableStyledTableCell style={{ width: '15%' }}>
              Category
            </PortfolioTableStyledTableCell>
            {hasPerformanceValues && (
              <PortfolioTableStyledTableCell style={{ width: '15%' }}>
                Value
              </PortfolioTableStyledTableCell>
            )}
            <PortfolioTableStyledTableCell
              style={{ width: '65%' }}
              align="right"
            >
              {hasPerformanceValues ? 'Target Allocation' : 'Allocation'}
            </PortfolioTableStyledTableCell>
          </TableRow>
        </TableHead>
        <PortfolioTableBody
          data={normalizeBasket(riskScore, basket, performanceValues)}
          priceOfEthInUsd={priceOfEthInUsd}
        />
      </Table>
    </TableContainer>
  );
};
