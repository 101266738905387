import { useMutation, UseMutationResult } from 'react-query';

import { queryClient } from 'AppWrapper';

import { ServerStateKey } from 'shared/enums/serverStateKey.enums';
import { CreateAccountRequest } from 'shared/interfaces/contracts/createAccount.interfaces';
import { createAccount } from 'shared/contract/contractHelpers';

export const useCreateAccount = (): UseMutationResult<
  void,
  Error,
  CreateAccountRequest
> =>
  useMutation<void, Error, CreateAccountRequest>(
    ({ contract, accountAddress, depositValue, riskScore, provider }) =>
      createAccount({
        contract,
        accountAddress,
        depositValue,
        riskScore,
        provider,
      }),
    {
      onSettled: (_, __, variables) => {
        return Promise.all([
          queryClient.refetchQueries([
            ServerStateKey.ETH_BALANCE_FROM_ACCOUNT,
            variables.accountAddress,
          ]),
          queryClient.refetchQueries([
            ServerStateKey.USER_BASKET_ADDRESS,
            variables.accountAddress,
          ]),
        ]);
      },
    }
  );
