import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

export const PortfolioTableStyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FAFAFA',
  },
  'th, td': {
    border: 0,
    height: '40px',
    padding: '0 24px',
  },
}));
