import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { Contract } from 'web3-eth-contract';
import { createContext, useContext, ReactNode } from 'react';
import {
  MEREO_CONTRACT_ABI,
  MEREO_CONTRACT_ADDRESS,
} from '../contract/contract';

const WEB3_NODE = `wss://polygon-mainnet.g.alchemy.com/v2/fwIW4F47IiYk6i4Qi7D75nvckpX00QJL`;

interface Web3SocketContextInterface {
  web3SocketContract: Contract | null;
}

const initializeWeb3SocketContextValues = () => ({
  web3SocketContract: null,
});

const { web3SocketContract } = initializeWeb3SocketContextValues();

const Web3SocketContext = createContext<Web3SocketContextInterface>({
  web3SocketContract
});

interface Web3SocketProviderProps {
  children: ReactNode;
}

export const Web3SocketContextProvider = ({
  children,
}: Web3SocketProviderProps) => {
  const client = new Web3(new Web3.providers.WebsocketProvider(WEB3_NODE));
  const web3SocketContract = new client.eth.Contract(
    MEREO_CONTRACT_ABI as AbiItem[],
    MEREO_CONTRACT_ADDRESS
  );

  return (
    <Web3SocketContext.Provider
      value={{ web3SocketContract }}
    >
      {children}
    </Web3SocketContext.Provider>
  );
};

export const useWeb3SocketContext = () => useContext(Web3SocketContext);
