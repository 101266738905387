import styled from 'styled-components';

import { Layout } from 'shared/components/Layout/Layout.components';
import { Avatar, Button, Card } from '@mui/material';
import EcashLogo from 'assets/images/logos/ecash-logo.png';
import KucoinLogo from 'assets/images/logos/kucoin-logo.png';
import XensorLogo from 'assets/images/logos/xensor-logo.png';

const BasketsContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  h1 {
    font-size: 34px;
    font-weight: 400;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 48px;
  }
`;

const CustomCard = styled(Card)`
  position: relative;

  .card-content {
    display: flex;
    flex-direction: column;
    padding: 48px 24px 24px;
    height: 395px;
    width: 270px;

    header {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }

    li {
      display: flex;
      margin-bottom: 4px;
      justify-content: space-between;

      h4 {
        margin: 0;
        color: #8e9092;
        font-size: 14px;
        font-weight: 700;
        line-height: 28px;
        text-transform: uppercase;

        .green {
          color: #0baa4b;
        }

        .red {
          color: #df4857;
        }

        span {
          color: #1e1e12;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-transform: none;
        }
      }
    }

    li:last-of-type {
      margin-bottom: 32px;
    }
  }

  .best-fit-ribbon {
    position: absolute;
    width: 75px;
    height: 75px;

    .triangle {
      border-style: solid;
      border-width: 75px 75px 0 0;
      border-color: #5a64d8 transparent transparent transparent;
      text-align: center;
    }

    p {
      position: absolute;
      margin: 0;
      top: 0;
      left: 0;
      height: 28px;
      width: 28px;
      color: white;
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      transform: translate(10px, 10px) rotate(-0.125turn);
      text-transform: uppercase;
    }
  }
`;

const TokensContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 36px 0;

  .token-image-border {
    border: 6px solid white;
    margin-left: -16px;
  }
`;

const CardsContainer = styled.section`
  display: flex;
  width: 100%;

  > div:not(:first-child) {
    margin-left: 38px;
  }
`;

interface CardOption {
  address: string;
  name: string;
  tokens: {
    token: string;
    logo: string;
  }[];
  numberOfTokens: number;
  percentChange: number;
  riskAdjustable: boolean;
  ctaUrl: string;
}

const cardOptions = [
  {
    address: '1',
    name: 'Starter',
    tokens: [
      {
        token: 'Ecash',
        logo: EcashLogo,
      },
      {
        token: 'Kucoin',
        logo: KucoinLogo,
      },
      {
        token: 'Xensor',
        logo: XensorLogo,
      },
    ],
    numberOfTokens: 1,
    percentChange: 5.5,
    riskAdjustable: true,
    ctaUrl: 'to be determined',
  },
  {
    address: '2',
    name: 'Starter',
    tokens: [
      {
        token: 'Ecash',
        logo: EcashLogo,
      },
      {
        token: 'Kucoin',
        logo: KucoinLogo,
      },
      {
        token: 'Xensor',
        logo: XensorLogo,
      },
    ],
    numberOfTokens: 1,
    percentChange: 5.5,
    riskAdjustable: true,
    ctaUrl: 'to be determined',
  },
  {
    address: '3',
    name: 'Starter',
    tokens: [
      {
        token: 'Ecash',
        logo: EcashLogo,
      },
      {
        token: 'Kucoin',
        logo: KucoinLogo,
      },
      {
        token: 'Xensor',
        logo: XensorLogo,
      },
    ],
    numberOfTokens: 1,
    percentChange: 5.5,
    riskAdjustable: true,
    ctaUrl: 'to be determined',
  },
  {
    address: '4',
    name: 'Starter',
    tokens: [
      {
        token: 'Ecash',
        logo: EcashLogo,
      },
      {
        token: 'Kucoin',
        logo: KucoinLogo,
      },
      {
        token: 'Xensor',
        logo: XensorLogo,
      },
    ],
    numberOfTokens: 1,
    percentChange: 5.5,
    riskAdjustable: true,
    ctaUrl: 'to be determined',
  },
];

export const Baskets = () => {
  return (
    <Layout width="1194px">
      <BasketsContainer>
        <h1>List of Baskets</h1>
        <h2>
          A basket is a collection of tokens from a variety of protocols,
          representing an index.
        </h2>
        <CardsContainer>
          {cardOptions.map(
            ({
              address,
              name,
              tokens,
              numberOfTokens,
              percentChange,
              riskAdjustable,
            }: CardOption) => {
              return (
                <div>
                  <CustomCard key={address}>
                    {name === 'Starter' && (
                      <div className="best-fit-ribbon">
                        <div className="triangle" />
                        <p>Best Fit</p>
                      </div>
                    )}
                    <div className="card-content">
                      <header>{name}</header>
                      <TokensContainer>
                        {tokens.map(({ logo, token }, i) => (
                          <Avatar
                            key={token}
                            className="token-image-border"
                            alt={token}
                            sx={{ height: 68, width: 68, zIndex: 999 - i }}
                            src={logo}
                          />
                        ))}
                      </TokensContainer>
                      <li>
                        <h4>Tokens/Asset Mix</h4>
                        <h4>
                          <span>{numberOfTokens}</span>
                        </h4>
                      </li>
                      <li>
                        <h4>Change</h4>
                        <h4>
                          <span
                            className={`${
                              percentChange >= 0 ? 'green' : 'red'
                            }`}
                          >{`${percentChange}%`}</span>
                        </h4>
                      </li>
                      <li>
                        <h4>Risk Adjustable</h4>
                        <h4>
                          <span>{riskAdjustable ? 'Yes' : 'No'}</span>
                        </h4>
                      </li>
                      <Button variant="outlined">Learn More</Button>
                    </div>
                  </CustomCard>
                </div>
              );
            }
          )}
        </CardsContainer>
      </BasketsContainer>
    </Layout>
  );
};
