import { useMutation, UseMutationResult } from 'react-query';

import { queryClient } from 'AppWrapper';

import { ServerStateKey } from 'shared/enums/serverStateKey.enums';
import { TransactionRequest } from 'shared/interfaces/contracts/transaction.interfaces';
import { withdraw } from 'shared/contract/contractHelpers';

export const useWithdraw = (): UseMutationResult<
  void,
  Error,
  TransactionRequest
> =>
  useMutation<void, Error, TransactionRequest>(
    ({ contract, accountAddress, value, provider }) =>
      withdraw(contract, accountAddress, value, provider),
    {
      onSettled: (_, __, variables) => {
        return Promise.all([
          queryClient.refetchQueries([
            ServerStateKey.ETH_BALANCE_FROM_ACCOUNT,
            variables.accountAddress,
          ]),
          queryClient.refetchQueries([ServerStateKey.PERFORMANCE_DATA]),
          queryClient.refetchQueries([
            ServerStateKey.BASKET_BALANCE_FROM_CONTRACT,
          ]),
        ]);
      },
    }
  );
