import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  useContext,
  useCallback,
} from 'react';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';

import {
  MEREO_CONTRACT_ABI,
  MEREO_CONTRACT_ADDRESS,
} from 'shared/contract/contract';
import {
  connectToMetaMask,
  requestConnectedWalletAccountFromMetaMask,
} from 'shared/webThree/webThreeHelpers';
import {
  createContract
} from 'shared/contract/contractHelpers';
import { useSnackbarContext } from './snackbar.contexts';
import { useLocation, useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let ethereum = window.ethereum;

const WEB3_NODE = `wss://polygon-mainnet.g.alchemy.com/v2/fwIW4F47IiYk6i4Qi7D75nvckpX00QJL`;

interface MetaMaskContextInterface {
  connectMetaMaskWalletHandler: () => void;
  setUserBasketAddress: (address: string) => void;
  accountAddress: string | null;
  provider: Web3 | null;
  contract: Contract | null;
  socketContract: Contract | null;
  userBasketAddress: string | null;
}

export const MetaMaskContext = createContext<MetaMaskContextInterface>({
  connectMetaMaskWalletHandler: () => null,
  setUserBasketAddress: () => null,
  accountAddress: null,
  provider: null,
  contract: null,
  socketContract: null,
  userBasketAddress: null,
});

interface MetaMaskContextProviderProps {
  children: ReactNode;
}

export const MetaMaskContextProvider = ({
  children,
}: MetaMaskContextProviderProps) => {
  const { handleOpen } = useSnackbarContext();
  const [accountAddress, setAccountAddress] = useState<string | null>(null);
  const [provider, setProvider] = useState<Web3 | null>(null);
  const [contract, setContract] = useState<Contract | null>(null);
  const [socketContract, setSocketContract] = useState<Contract | null>(null);
  const [userBasketAddress, setUserBasketAddress] = useState<string | null>(
    null
  );
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', async (data: any) => {
        if (pathname === '/create/connect') {
          navigate('/create/risk-assessment');
        }
        window.location.reload();
      });

      window.ethereum.on('chainChanged', (data: any) => {
        if (pathname === '/create/connect') {
          navigate('/create/risk-assessment');
        }
        window.location.reload();
      });
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', () => {
          console.log('accounts listener removed');
        });

        window.ethereum.removeListener('chainChanged', () => {
          console.log('chainChanged listener removed');
        });
      }
    };
  }, [contract, navigate, pathname]);

  const connectMetaMaskWalletHandler = useCallback(async () => {
    try {
      if (!window.ethereum && window.ethereum.isMetaMask) {
        throw Error('Metamask not installed');
      }

      await connectToMetaMask();
      const web3 = new Web3(window.ethereum || Web3.givenProvider);
      const web3Socket = new Web3(new Web3.providers.WebsocketProvider(WEB3_NODE));
      // const web3 = new Web3('http://127.0.0.1:8545');
      setProvider(web3);

      const fetchedConnectedAccount =
        await requestConnectedWalletAccountFromMetaMask(web3);
      console.log('fetchedConnectedAccount:', fetchedConnectedAccount);
      setAccountAddress(fetchedConnectedAccount);

      const createdContract = await createContract(
        web3,
        fetchedConnectedAccount,
        MEREO_CONTRACT_ABI as AbiItem[],
        MEREO_CONTRACT_ADDRESS
      );
      setContract(createdContract);

      const createdSocketContract = await createContract(
        web3Socket,
        fetchedConnectedAccount,
        MEREO_CONTRACT_ABI as AbiItem[],
        MEREO_CONTRACT_ADDRESS
      );
      setSocketContract(createdSocketContract);
    } catch (error: any) {
      handleOpen('Error: ' + error.message);
    }
  }, [handleOpen]);

  return (
    <MetaMaskContext.Provider
      value={{
        connectMetaMaskWalletHandler,
        setUserBasketAddress,
        accountAddress,
        provider,
        contract,
        socketContract,
        userBasketAddress,
      }}
    >
      {children}
    </MetaMaskContext.Provider>
  );
};

export const useMetaMaskContext = () => useContext(MetaMaskContext);
