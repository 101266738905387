import { ReactNode } from 'react';
import styled from 'styled-components';

interface LayoutProps {
  children: ReactNode;
  paddingTop?: string;
  paddingBottom?: string;
  width?: string;
}

const OuterLayoutContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

interface LayoutContainerProps extends Omit<LayoutProps, 'children'> {}

const LayoutContainer = styled.div`
  padding-top: ${({ paddingTop }: LayoutContainerProps) => paddingTop};
  padding-bottom: ${({ paddingBottom }: LayoutContainerProps) => paddingBottom};
  width: ${({ width }: LayoutContainerProps) => width};
`;

export const Layout = ({
  children,
  paddingTop = '112px',
  paddingBottom = '64px',
  width = '850px',
}: LayoutProps) => (
  <OuterLayoutContainer>
    <LayoutContainer
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
    >
      {children}
    </LayoutContainer>
  </OuterLayoutContainer>
);
