import * as yup from 'yup';

export interface FormSchema {
  depositValue: string;
}

export const createInitialValues = () => ({
  depositValue: '0.0',
});

export const schema = yup
  .object({
    depositValue: yup
      .string()
      .matches(
        /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
        'Only numbers and decimals allowed'
      ),
  })
  .required();
