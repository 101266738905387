export enum EthereumMainnetTokenAddress {
  ETH = '0xc778417E063141139Fce010982780140Aa0cD5Ab',
  AXS = '0x8547d8a5d47a683E14E2015D39192929756cd9DD',
  BTC = '0x8450f1cd869d2213fE262917B956eD669B60970C',
  LUNA = '0x85268901531da396d0bE41A98a435139A4c11209',
  SAND = '0xA75Ca57084d165f75b7D7A129f2E714B616CF3D5',
  SOL = '0x594d1738727CA3712a5e786Dce23716E03924c8A',
}


/**
 * @description ETH, BTC are wrappers
 */
export enum PolygonMainnetTokenAddress {
  ETH = '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  BTC = '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
  SAND = '0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683',
  SOL = '0x7DfF46370e9eA5f0Bad3C4E29711aD50062EA7A4',
  LINK = '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
  MANA = '0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4',
}
