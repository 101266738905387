import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Layout } from 'shared/components/Layout/Layout.components';

import CongratulationsBackgroundImage from 'assets/images/background/congratulations-background.png';
import MEREO_MARK_LOGO from 'assets/images/logos/mereo-mark-logo.png';
import { useEffect } from 'react';
import { useMetaMaskContext } from '../../../shared/contexts/metamask.contexts';
import { getUserBasketAddress } from '../../../shared/contract/contractHelpers';

const CongratulationsContainer = styled.section`
  position: relative;
  
  .congratulations-background-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 128px 106px 0;
    background-color: #fffbf4;
    background-size: contain;
    background-position: top;
  }

  .congratulations-background {
    background-size: contain;
    background-position: top;
    background-image: url(${CongratulationsBackgroundImage});
    background-repeat: no-repeat;
    width 100%;
    height: 100%;
  }

  .congratulations-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 1;

    img {
      height: 124px;
      width: 108px;
      margin-bottom: 34px;
    }

    h3 {
      margin-bottom: 21px;
    }

    &-body-text {
      width: 608px;
      text-align: center;
      margin-bottom: 60px;
    }
  }
`;

export const Congratulations = () => {
  const navigate = useNavigate();
  const { setUserBasketAddress, socketContract, accountAddress } =
    useMetaMaskContext();

  useEffect(() => {
    (async () => {
      if (!socketContract || !accountAddress) return;
      const userBasketAddress = await getUserBasketAddress(
        socketContract,
        accountAddress
      );
      userBasketAddress && setUserBasketAddress(userBasketAddress);
    })();

    const delayInSeconds = 10;
    const timer1 = setTimeout(async () => {
      navigate('/dashboard/portfolio');
    }, delayInSeconds * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [navigate, socketContract, accountAddress, setUserBasketAddress]);

  const handleClick = async () => {
    if (socketContract && accountAddress) {
      await getUserBasketAddress(
        socketContract,
        accountAddress
      );
    }
    navigate('/dashboard/portfolio');
  };

  return (
    <CongratulationsContainer>
      <div className="congratulations-background-container">
        <div className="congratulations-background" />
      </div>
      <Layout>
        <div className="congratulations-content">
          <img src={MEREO_MARK_LOGO} alt="egg-basket-logo" />
          <Typography variant="h3">Congratulations!</Typography>
          <Typography
            className="congratulations-content-body-text"
            variant="body1"
          >
            Your account will be active within 24 hours once your fund is fully
            invested. You’ll be redirected to your dashboard in 10 seconds...
          </Typography>
          <Button
            variant="outlined"
            onClick={handleClick}
          >
            Launch dashboard
            <ArrowForwardIcon className="create-risk-assessment-next-button-icon" />
          </Button>
        </div>
      </Layout>
    </CongratulationsContainer>
  );
};
