export const coinSymbols = [
  'eth',
  'btc',
  'mana',
  'link',
  'sand',
  'sol',
  'matic',
];

export const WBTCAddress = '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6';

export const addressToCoinGeckoId: { [key: string]: string } = {
  '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619': 'ethereum',
  [WBTCAddress]: 'bitcoin',
  '0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683': 'the-sandbox',
  '0x7DfF46370e9eA5f0Bad3C4E29711aD50062EA7A4': 'solana',
  '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39': 'chainlink',
  '0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4': 'decentraland',
  '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270': 'matic-network',
};

export const addressToCryptoWatchId: { [key: string]: string } = {
  '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619': 'eth',
  [WBTCAddress]: 'btc',
  '0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683': 'sand',
  '0x7DfF46370e9eA5f0Bad3C4E29711aD50062EA7A4': 'sol',
  '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39': 'link',
  '0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4': 'mana',
  '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270': 'matic',
};
